#content > .resources {
    .title {
        margin-bottom: 2rem;

        h2 {
            color: #52050A;
        }
    }

    .text {
        font-size: 1.125rem;
        margin-top: 2rem;

        a {
            color: #F6E27F;
            font-weight: 800;
        }

        img {
            margin-left: 0.5rem;
        }
    }

    .resources-grid {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        > div:first-child {
            display: flex;
            margin-bottom: 1.5rem;
            justify-content: flex-start;
            width: 100%;

            > span {
                color: #7E8192;
                font-size: 1.125rem;
            }
        }

        > div:last-child {
            justify-content: flex-start;
        }
    }

    @include media-breakpoint-down(sm) {
        .resources-grid > div:last-child {
            justify-content: center;
        }
    }
}

.unitas-site #content > .resources {
    .title h2 {
        color: #00400E;
        font-weight: 900;
    }

    @include media-breakpoint-down(sm) {
        .title h2 {
            font-size: 3rem;
        }
    }
}

.prime-site #content > .resources {
    background: #000;

    .pattern {
        background: url('../static/prime/blog.png') top right no-repeat;
        background-size: 63%;
        mix-blend-mode: lighten;
    }

    .title h2 {
        color: #FFF;
        font-family: ArdelaEdgeX02-Black;
        font-size: 5rem;
        font-weight: 400;
    }

    .resources-grid > div:first-child span {
        color: #D3CDD0;
    }
}
