.home, .looking-for-a-job {
    .search-roles {
        background: url('../static/huddle/open-roles-pattern.svg') top repeat #FF958C;
        background-size: contain;

        .box {
            align-items: flex-start;
            background: #310A31;
            border-radius: 3rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 3rem;

            &.roles-box {
                padding: 3rem 4rem;
            }
        }

        .roles-header {
            align-items: center;
            align-self: stretch;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: space-between;

            a {
                align-items: center;
                color: #F6E27F;
                display: flex;
                font-size: 1.25rem;
                font-weight: 800;
            }

            img {
                margin-left: 1rem;
                width: 1.25rem;
            }
        }

        .roles-container {
            flex-wrap: nowrap;
            overflow: scroll;
            margin: 0;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            width: 100%;

            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        }

        @include media-breakpoint-down(md) {
            .box {
                border-radius: 2rem;
                padding: 1.5rem 0.75rem;

                &:not(.roles-box):not(.job-box) {
                    gap: 1.5rem;
                }

                h2 {
                    font-size: 3rem;
                    padding-left: 0.75rem;
                }

                h3 {
                    font-size: 2.375rem;
                }

                &.roles-box {
                    padding: 2rem 0.75rem;
                }
            }

            .roles-header {
                align-items: flex-start;
                flex-direction: column;
                padding-left: 0.75rem;
            }
        }
    }
}

.unitas-site {
    .home, .looking-for-a-job {
        .search-roles {
            background: url('../static/unitas/open-roles-pattern.svg') top left no-repeat, url('../static/unitas/open-roles-pattern-2.svg') bottom right no-repeat #F9F9F9;

            .box {
                background: #1A1A1A;
                border-radius: 1.5rem;
                padding: 2rem 3rem 2.5rem;

                &.roles-box {
                    border-radius: 1.5rem;
                }

                h2 {
                    font-size: 4rem;
                }
            }

            .roles-header a {
                color: #F49944;
            }

            @include media-breakpoint-down(md) {
                background-size: 40%;

                .box {
                    padding: 1.5rem 0.75rem;

                    h2 {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}
