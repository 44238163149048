.company .company-hero {
    background: #000;
    overflow: hidden;

    .inner-container {
        padding-bottom: 0;
    }

    .content {
        margin-top: 2rem;
    }

    .text-container {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 45.0625rem;

        > img {
            position: absolute;
            left: 105%;
            top: -18rem;
            width: 60rem;
            z-index: -5;
        }
    }

    .title {
        margin-top: 2rem;

        img {
            height: 2rem;
        }

        h1 {
            color: #FF3D00;
            font-family: ArdelaEdgeX02-Black;
            font-size: 7rem;
            font-weight: 400;
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 2rem;
        }

        .service, .sector {
            align-items: center;
            display: flex;
            gap: 1.5rem;

            span {
                color: #FF3D00;
                font-size: 1.25rem;
            }

            span:first-child {
                color: rgba(255, 61, 0, 0.70);
                width: 4.5rem;
            }
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        margin-top: 3rem;
        max-width: 37.0625rem;

        p {
            color: #D3CDD0;
            font-size: 1.25rem;
            line-height: 2rem; /* 160% */
        }

        .btn-container {
            justify-content: flex-end;
            margin-top: 2rem;
        }
    }

    .company-container {
        margin-top: 6rem;
        align-items: flex-start;
        background: #FF3D00;
        display: flex;
        gap: 5%;
        padding: 4rem;
        width: 100%;

        .company-content {
            h2, p {
                color: #000;
            }

            p {
                margin: 2rem 0 3rem;
            }

            .company-contact {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                > div, a {
                    align-items: center;
                    display: flex;
                }

                svg {
                    height: unset;
                    margin-right: 1.5rem;
                    width: 1.5rem;

                    path {
                        stroke: #FFF;
                    }
                }

                span, a {
                    color: #FFF;
                    font-size: 1.25rem;
                    font-weight: 700;
                }
            }
        }

        .divider {
            background: rgba(0, 0, 0, 0.15);
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 0.125rem;
        }

        .boss-content {
            padding-left: 5%;
            position: relative;
            max-width: 38%;

            h5 {
                color: #000;
                font-family: ArdelaEdgeX02-Black;
            }

            .boss {
                margin-top: 3rem;

                .boss-img-container img {
                    width: 9.375rem;
                }

                .boss-details {
                    margin: 2rem 0 1.5rem;

                    h5 {
                        margin-bottom: 0.5rem;
                    }

                    span {
                        color: rgba(0, 0, 0, 0.70);
                        font-size: 1.125rem;
                    }

                    p {
                        color: #000;
                        font-size: 1rem;
                        margin-top: 1rem;
                    }
                }

                .boss-contact {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    div, a {
                        align-items: center;
                        display: flex;
                    }

                    svg {
                        height: unset;
                        margin-right: 0.75rem;
                        width: 1.25rem;

                        path {
                            stroke: #FFF;
                        }
                    }

                    div:last-child svg path {
                        fill: #FFF;
                        stroke: none;
                    }

                    span {
                        font-size: 1.125rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .text-container {
            position: unset;

            > img {
                left: unset;
                right: -4%;
                top: -1rem;
                width: 44%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .title {
            margin-top: 0;
        }

        .content {
            margin-top: 0;
        }

        .text-container {
            width: unset;

            > h5 {
                font-size: 1.75rem;
            }
        }

        .title h1 {
            font-size: 4rem;
        }

        .text {
            margin-top: 3rem;
            max-width: unset;
        }

        .company-container {
            flex-direction: column;
            gap: 4rem;
            margin-top: 4rem;
            padding: 4rem 1.5rem;

            .divider {
                height: 0.125rem;
                width: 100%;
            }

            .boss-content {
                max-width: unset;
                padding-top: 4rem;
                padding-left: 0;

                h5 {
                    font-size: 1.75rem;
                }

                .boss .boss-img-container img {
                    min-width: 9.375rem;
                    width: 50%;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .company-container {
            .company-content {
                h2 {
                    font-size: 2.5rem;
                }
            }
        }
    }
}
