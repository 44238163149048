.roles-box, .jobs {
  .role-grid-item {
    max-width: 19.875rem;

    @include media-breakpoint-down(md) {
      max-width: 21.9375rem;
    }
  }

  .role {
    border-radius: 1.5rem;
    border: 1px solid rgba(49, 10, 49, 0.15);
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 100%;
    min-width: 18.375rem;

    .role-container {
      align-items: flex-start;
      display: flex;
      flex: 1 0 0;
      padding: 1.5rem 1.5rem 2rem;
      flex-direction: column;
      height: 100%;
    }

    .role-type {
      align-items: center;
      border-radius: 6.25rem;
      display: flex;
      gap: 0.375rem;
      padding: 0.375rem 0.75rem;

      &.permanent {
        background: #E3EBFF;

        span {
          color: #2A64F6;
        }
      }

      &.contract {
        background: #FFCCFA;

        span {
          color: #991F8D;
        }
      }

      span {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    .role-title {
      flex-grow: 1;
      margin: 1rem 0;
      max-height: 6.375rem;

      h6 {
        color: #1A1A1A;
        font-size: 1.75rem;
        font-weight: 800;
      }
    }

    .role-details {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      span {
        align-items: center;
        color: #1A1A1A;
        display: flex;
        font-size: 0.875rem;
      }

      svg {
        height: unset;
        margin-right: 0.5rem;
        width: 1.25rem;

        path {
          stroke: #FF958C;
        }
      }
    }

    .role-description {
      margin: 1.25rem 0 1.5rem;

      p {
        color: #7E8192;
        font-size: 0.875rem;
      }
    }

    .btn {
      font-size: 1rem;
      padding: 0.75rem 2rem;
      width: 100%;
    }
  }
}

.unitas-site {
  .roles-box, .jobs {
    .role {
      border-radius: 0.75rem;

      .role-container {
        padding: 1.5rem;
      }

      .role-title h6 {
        font-size: 2rem;
        font-weight: 900;
      }

      .role-details svg path {
        stroke: #C57B36;
      }
    }
  }
}

.prime-site {
  .roles-box, .jobs {
    .role {
      border-radius: 0;

      .role-type {
        background: none;
        padding: 0;

        span {
          color: #7E8192;
          font-size: 0.875rem;
          font-weight: 700;
          letter-spacing: 0.105rem;
          text-transform: uppercase;
        }
      }

      .role-title {
        margin: 0.5rem 0 1.5rem;

        h6 {
          color: #000;
          font-family: ArdelaEdgeX02-Black;
          font-weight: 400;
        }
      }

      .role-details {
        svg path {
          stroke: #EB52F9;
        }

        span {
          color: #000;
        }
      }

      .role-description {
        margin: 1.5rem 0;
      }

      .btn {
        padding: 0.69rem 2rem 0.81rem;
      }
    }
  }
}
