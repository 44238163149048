.related-resources, .resource-page {
    background: #FFF;
    background-size: cover;

    .resources-box {
        align-items: flex-start;
        background: #52050A;
        border-radius: 3rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem 4rem;

        .loader {
            align-self: center;
            flex-grow: 1;
        }
    }

    .resources-header {
        align-items: center;
        align-self: stretch;
        display: flex;
        justify-content: space-between;

        a {
            align-items: center;
            color: #F6E27F;
            display: flex;
            font-size: 1.25rem;
            font-weight: 800;
        }

        svg {
            height: unset;
            margin-left: 1rem;
            width: 1.25rem;

            path {
                stroke: #FF958C;
            }
        }
    }

    .resources-container {
        flex-wrap: nowrap;
        overflow: scroll;
        margin: 0;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        width: 100%;

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }

    @include media-breakpoint-down(md) {
        .resources-box {
            border-radius: 2rem;
            min-height: 41.125rem;
            padding: 2rem 0.75rem;

            h3 {
                font-size: 2.375rem;
                margin-bottom: 1rem;
            }
        }

        .resources-header {
            align-items: flex-start;
            flex-direction: column;
            padding-left: 0.75rem;
        }
    }
}

.unitas-site {
    .related-resources, .resource-page {
        .resources-box {
            background: #00400E;
            border-radius: 1.5rem;
        }

        .resources-header {
            a {
                color: #F49944;
                font-weight: 600;
            }

            svg path {
                stroke: #C57B36;
            }
        }
    }
}

.prime-site {
    .related-resources, .resource-page {
        .resources-box {
            background: transparent;
            border-radius: 0;
            padding: 0;
        }

        .resources-header {
            a {
                color: #EB52F9;
                font-weight: 700;
            }

            svg path {
                stroke: #EB52F9;
            }
        }
    }
}
