.related-roles, .work-for-us, .resource-page {
    background: #FFF;

    .roles-box {
        align-items: flex-start;
        background: #310A31;
        border-radius: 3rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem 4rem;
    }

    .roles-header {
        align-items: center;
        align-self: stretch;
        display: flex;
        justify-content: space-between;

        a {
            align-items: center;
            color: #F6E27F;
            display: flex;
            font-size: 1.25rem;
            font-weight: 800;
            margin-right: 1rem;
        }

        img {
            margin-left: 1rem;
            width: 1.25rem;
        }
    }

    .roles-container {
        flex-wrap: nowrap;
        overflow: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        width: 100%;

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }

    @include media-breakpoint-down(md) {
        .roles-box {
            border-radius: 2rem;
            padding: 2rem 0.75rem;

            h3 {
                font-size: 2.375rem;
                margin-bottom: 1rem;
            }
        }

        .roles-header {
            align-items: flex-start;
            flex-direction: column;
            padding-left: 0.75rem;
        }
    }
}

.related-roles .inner-container {
    padding-top: 1.5rem;
}

.work-for-us {
    .roles-box {
        background: #F6E27F;
    }

    .roles-header {
        h3, a {
            color: #310A31;
        }
    }
}

.unitas-site {
    .related-roles, .work-for-us, .resource-page {
        .roles-box {
            background: #1A1A1A;
            border-radius: 1.5rem;
        }

        .roles-header a {
            color: #F49944;
            font-weight: 600;
        }
    }

    .work-for-us {
        background: url('../static/unitas/open-roles-pattern.svg') top left no-repeat, url('../static/unitas/open-roles-pattern-2.svg') bottom right no-repeat #F9F9F9;

        .roles-box {
            background: #56422F;
        }

        .roles-header {
            h3, a {
                color: #FFF;
            }
        }
    }
}

.prime-site .work-for-us {
    .roles-box {
        background: #FFB800;
        border-radius: 0;
        gap: 3rem;
    }

    .roles-header h3 {
        color: #000;
        font-size: 2.5rem;
    }
}
