@import 'bootstrap';
@import 'media_queries';

@import 'home_hero';
@import 'home_looking_for_a_job';
@import 'jobs';
@import 'leaders';
@import 'looking_to_hire';
@import 'embedded_talent';
@import 'executive_search';
@import 'specialism_hero';
@import 'leadership_hero';
@import 'company_hero';

@import 'contact_form';
@import 'follow_us';
@import 'contract_hires';
@import 'our_story';
@import 'origin_story';
@import 'pro_points';
@import 'search_bar';
@import 'search_roles';
@import 'specialisms';
@import 'specialists';
@import 'types_of_roles';
@import 'work_for_us';
@import 'corporate_measure';
@import 'the_prime_group';

@import 'role';
@import 'roles';
@import 'resource';
@import 'resources';
@import 'resources_nav';
@import 'resources_page';
@import 'resource_page';
@import 'info_container';
@import 'contact_container';
@import 'contact_visit_container';

@import 'navigator';
@import 'footer';
@import 'legal';

html {
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1320px !important;
  position: relative;
  z-index: 1;
}

.inner-container {
  display: flex;
  flex-direction: column;
  padding: 6rem 1.5rem;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding: 4rem 0.75rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6, p, span, label {
  color: #FFF;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

a {
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  text-decoration: none;

  &:not([href]):not([class]) {
    cursor: initial;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}

h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.5rem;
}

h5 {
  font-size: 1.75rem;
}

h6 {
  font-size: 1.25rem;
}

p {
  font-size: 1.125rem;
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h5 {
    font-size: 2rem;
  }

  h6 {
    font-size: 1.5rem;
  }
}

.unitas-site {
  h1, h2, h3, h4, h5, h6 {
    font-family: Fraunces;
    font-weight: 400;
  }

  p, span, a, label {
    font-family: Poppins;
    font-weight: 400;
  }

  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 5rem;
  }

  h5 {
    font-size: 2rem;
  }
}

.prime-site {
  h1, h2, h3, h4, h5, h6, label {
    font-family: ArdelaEdgeX02-Medium;
    font-weight: 400;
  }

  p, span, a {
    font-family: Plus Jakarta Sans;
    font-weight: 500;
  }
}

.btn {
  align-items: center;
  border: 0;
  border-radius: 62.5rem;
  cursor: pointer;
  display: flex;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  justify-content: center;
  padding: 1rem 2rem;
  white-space: nowrap;
  width: fit-content;

  &:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
  }

  &[disabled] {
    opacity: 1;
  }

  .loader svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus-visible,
.btn-primary[disabled] {
  background: #02C39A;
  border: 3px solid #9B7ED4;
  box-shadow: 0 8px 0 0 #310A31;
  color: #FFE97B;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus-visible,
.btn-secondary[disabled] {
  background: #F6E27F;
  border: 3px solid #FF958C;
  box-shadow: 0 8px 0 0 #310A31;
  color: #2BAC91;
}

.unitas-site {
  .btn {
    border-radius: 0.75rem;
    font-family: Poppins;
    font-weight: 600;
  }

  .btn-primary, .btn-primary:hover {
    background: #3A84C9;
    border: 0;
    box-shadow: none;
    color: #FFF;
  }

  .btn-secondary, .btn-secondary:hover {
    background: #C57B36;
    border: 0;
    box-shadow: none;
    color: #FFF;
  }
}

.prime-site {
  .btn {
    border-radius: 0;
    font-family: Plus Jakarta Sans;
    font-weight: 700;
    padding: 1rem 2rem 1.12rem;
  }

  .btn-primary, .btn-primary:hover {
    background: #163BF2;
    border: 0;
    box-shadow: none;
    color: #FFF;
  }

  .btn-secondary, .btn-secondary:hover {
    background: #EB52F9;
    border: 0;
    box-shadow: none;
    color: #FFF;
  }
}

.btn-container {
  align-items: center;
  align-self: stretch;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

input {
  border: 0;
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:not([type='checkbox']) {
    width: 100%;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7E8192;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #7E8192;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #7E8192;
  }
}

.unitas-site input {
  font-family: Poppins;
  font-weight: 400;
}

.prime-site input {
  font-family: Plus Jakarta Sans;
  font-weight: 700;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-weight: 500;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: 500;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    font-weight: 500;
  }
}

.textfield {
  flex-grow: 1;
  position: relative;

  .input-container {
    align-items: center;
    align-self: stretch;
    display: flex;

    input {
      background: #FFF;
      border-radius: 62.5rem;
      padding: 1rem;
    }
  }

  &:has(label) {
    .input-container {
      margin-top: 0.75rem;
    }
  }

  label {
    font-size: 1.25rem;
  }
}

.error-msg, .success-msg {
  bottom: -1.5rem;
  color: #de695c !important;
  font-size: 0.875rem;
  height: fit-content;
  position: absolute;
}

.success-msg {
  color: #65de5c !important;
}

.prime-site {
  .error-msg {
    color: #f6ff00 !important;
    font-weight: 700;
  }

  .success-msg {
    color: #2cff00 !important;
    font-weight: 700;
  }
}

#fallback {
  height: 100vh;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  svg {
    animation: spin 1.75s infinite linear;
    height: 2rem;
    width: 2rem;

    path {
      stroke: #7E8192;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 600px) {
  .error-msg {
    max-width: 100%;
  }
}

@font-face {
  font-family: ArdelaEdgeX02-Regular;
  src: local('ArdelaEdgeX02-Regular'), url('../view/core/ardela-edge-x02-regular.ttf') format('truetype');
}

@font-face {
  font-family: ArdelaEdgeX02-Medium;
  src: local('ArdelaEdgeX02-Medium'), url('../view/core/ardela-edge-x02-medium.ttf') format('truetype');
}

@font-face {
  font-family: ArdelaEdgeX02-Black;
  src: local('ArdelaEdgeX02-Black'), url('../view/core/ardela-edge-x02-black.ttf') format('truetype');
}

@font-face {
  font-family: ArdelaEdgeX02-ExtraBold;
  src: local('ArdelaEdgeX02-ExtraBold'), url('../view/core/ardela-edge-x02-extrabold.ttf') format('truetype');
}
