.home, .looking-to-hire {
    .embedded-talent {
        background: #8762CB;
        overflow: hidden;

        .content {
            align-self: flex-end;
            max-width: 38.5rem;
            position: relative;

            > img {
                height: 130%;
                position: absolute;
                right: 114.5%;
                top: -15%;
            }
        }

        .title {
            a {
                color: #F6E27F;
                background: #310A31;
            }
        }

        .text {
            margin-top: 2rem;

            p {
                color: #FFF;
                font-size: 1.125rem;
                font-weight: 600;

                a {
                    color: #F6E27F;
                    font-weight: 700;
                    white-space: nowrap;
                }

                img {
                    margin-left: 0.5rem;
                }
            }
        }

        .grid-container {
            margin-top: 2.5rem;
            margin-left: -1.5rem;
            width: calc(100% + 3rem);
        }

        .info-container {
            align-items: flex-start;
            display: flex;
            flex: 1 0 0;
            flex-direction: column;
            padding: 1rem;

            .info-icon {
                background: #FF958C;
                border-radius: 62.5rem;
                height: 4rem;
                width: 4rem;

                > svg {
                    height: 100%;
                    padding: 1rem;
                    width: 100%;

                    path {
                        stroke: #FFF;
                    }
                }
            }

            h5 {
                color: #FFF;
                font-weight: 800;
                margin: 1.5rem 0 1rem;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .btn-container {
            margin-top: 3rem;
        }

        @include media-breakpoint-up(md) {
            .grid-container > div {
                flex-basis: 50%;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @include media-breakpoint-down(md) {
            background: url('../static/huddle/embedded-talent-mob.png') top right no-repeat #8762CB;
            background-size: 17rem;

            .content {
                margin-top: 4rem;
                max-width: unset;

                > img {
                    display: none;
                }
            }

            .info-container {
                align-items: center;

                h5 {
                    font-size: 1.75rem;
                    margin: 1rem 0 0.5rem;
                }
            }

            .grid-container {
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            .grid-container {
                margin-top: 1.5rem;
            }

            .btn-container {
                margin-top: 2.5rem;
            }
        }
    }
}

.unitas-site {
    .home, .looking-to-hire {
        .embedded-talent {
            background: url('../static/unitas/embedded-talent.jpeg') center no-repeat;
            background-size: cover;

            .title a {
                background: none;
                color: #FFF;
                font-family: Fraunces;
            }

            .text {
                p {
                    color: #FFF;
                    font-weight: 400;
                }
            }

            .info-container {
                .info-icon {
                    background: #B1C9B1;
                }

                h5 {
                    font-weight: 400;
                }

                p {
                    font-weight: 400;
                }
            }

            @include media-breakpoint-down(md) {
                background:  url('../static/unitas/embedded-talent-mob.jpg') top right no-repeat #3A84C9;
                background-size: 85%;
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 3rem;
                }

                .info-container h5 {
                    font-size: 2rem;
                }
            }
        }
    }
}
