.legal {
    .inner-container {
        max-width: 58.25rem;
    }

    &.privacy-policy {
        .title h2 {
            color: #1A1A1A;
            font-size: 5rem;
            font-weight: 900;
        }

        .text {
            margin-top: 4rem;

            p {
                color: #1A1A1A;
                font-family: Montserrat;
                font-size: 1rem;
                line-height: 1.75rem;
                word-wrap: break-word;

                &:has(strong) {
                    color: #7E8192;
                    font-size: 1.5rem;
                    font-weight: 800;
                    line-height: 2.25rem; /* 150% */
                    margin: 2rem 0 1rem;
                }

                &.intro {
                    color: #7E8192;
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 2rem; /* 160% */
                    margin-bottom: 3rem;
                }

                &.updated {
                    color: #7E8192;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: normal;
                    margin-top: 4rem;
                    text-align: right;
                }

                a {
                    color: #310a31;
                    font-weight: 800;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &.privacy-policy {
            .title h2 {
                font-size: 3rem;
            }

            .text {
                margin-top: 3rem;

                p.updated {
                    margin-top: 3rem;
                    text-align: left;
                }
            }
        }
    }
}

.unitas-site .legal {
    &.privacy-policy {
        .text {
            p {
                font-family: Poppins;
                font-weight: 400;

                &:has(strong) {
                    font-weight: 600;
                }

                a {
                    color: #1A1A1A;
                    font-weight: 600;
                }
            }
        }
    }
}

.prime-site .legal {
    &.privacy-policy {
        background: #000;

        .pattern {
            background: url('../static/prime/prime-pattern.png') top right no-repeat;
            background-size: 40%;
            mix-blend-mode: lighten;
        }

        .title h2 {
            color: #FFF;
            font-family: ArdelaEdgeX02-Black;
            font-weight: 400;
        }

        .text {
            p {
                color: rgba(211, 205, 208, 0.70);
                font-family: Plus Jakarta Sans;
                font-weight: 500;

                &.intro {
                    color: #D3CDD0;
                }

                &:has(strong) {
                    color: #D3CDD0;
                    font-weight: 700;
                }

                a {
                    color: #EB52F9;
                    font-weight: 700;
                }
            }
        }
    }
}
