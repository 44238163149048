.footer {
    background: url('../static/huddle/footer-pattern.svg') top center repeat #FF958C;
    background-size: cover;

    .inner-container {
        padding-bottom: 2rem;
    }

    .content {
        align-self: stretch;
        gap: 6rem;

        .title {
            align-items: center;
            display: flex;
            gap: 2rem;
            justify-content: center;

            img {
                width: 11.25rem;
            }
        }

        h6 {
            color: #310A31;
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .box {
        background: #310A31;
        border-radius: 3rem;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        padding: 4rem 4rem 2rem;

        &.job-box, &.hire-box {
            align-items: center;
            gap: 2.5rem;
            height: 100%;
            justify-content: space-between;
            padding: 4rem;
        }

        h3 {
            white-space: nowrap;
        }

        .text {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            text-align: center;
        }

        p {
            color: rgba(255, 255, 255, 0.70);
            font-size: 1rem;
        }

        .btn-container {
            gap: 2rem;
            justify-content: center;
        }

        .btn:not(.btn-secondary) {
            background: transparent;
            border: 3px solid #F6E27F;
            color: #F6E27F;
        }
    }

    .footer-box {
        .footer-container {
            align-items: flex-start;
            align-self: stretch;
            display: flex;
            justify-content: space-between;
        }

        .footer-company {
            align-items: flex-start;
            display: flex;
            flex-direction: column;

            img {
                height: 5rem;
            }

            p {
                margin-top: 1.5rem;
            }
        }

        .footer-content {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            max-width: 19.5rem;

            p {
                color: #FFF;
                margin-top: 1.5rem;
                text-align: left;
            }

            .footer-contact {
                align-items: flex-start;
                align-self: stretch;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                & > div {
                    align-items: center;
                    display: flex;
                }

                svg {
                    height: unset;
                    width: 1.25rem;

                    path {
                        stroke: #FF958C;
                    }
                }

                span {
                    color: #F6E27F;
                    font-size: 1rem;
                    font-weight: 800;
                    margin-left: 1rem;
                }
            }

            .footer-socials {
                align-items: center;
                align-self: stretch;
                display: flex;
                gap: 2rem;

                svg {
                    height: 1.75rem;
                    width: unset;

                    path {
                        fill: #F6E27F;
                        stroke: none;
                    }
                }
            }
        }

        .footer-links {
            align-items: flex-start;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;

            .footer-links-column {
                display: flex;
                flex-direction: column;
                margin-right: 2rem;
                width: 31%;

                &:nth-child(2) {
                    width: 38%;
                }

                &:nth-child(3) {
                    margin-right: 1rem;
                }
            }

            h6 {
                color: #FFF;
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1.5rem;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    margin: 1.25rem 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                a, span {
                    color: #F6E27F;
                    font-size: 1rem;
                    font-weight: 800;
                }

                span {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                }

                svg {
                    margin-left: 0.5rem;
                    width: 0.625rem;

                    path {
                        stroke: #F6E27F;
                    }
                }
            }
        }

        .footer-divider {
            margin: 0 1.5rem;
        }

        .divider {
            background: rgba(255, 255, 255, 0.15);
            display: flex;
            height: 1px;
            margin: 6rem 0 2rem;
        }

        .footer-legal {
            align-items: center;
            align-self: stretch;
            display: flex;
            justify-content: space-between;

            > div {
                flex-wrap: nowrap;

                > div {
                    align-items: center;
                    display: flex;
                }
            }

            .footer-legal-prime {
                a {
                    align-items: center;
                    color: rgba(255, 255, 255, 0.50);
                    display: flex;
                    font-size: 0.875rem;
                    font-weight: 600;
                    gap: 1rem;
                    white-space: nowrap;
                }

                img {
                    width: 11.24rem;
                }
            }

            .footer-copyright span {
                align-items: center;
                color: rgba(255, 255, 255, 0.50);
                display: flex;
                font-size: 0.875rem;
                gap: 1rem;
                text-align: right;
            }

            a {
                color: #F6E27F;
                font-size: 0.875rem;
                font-weight: 800;
            }
        }
    }

    @include media-breakpoint-down(md) {
        background: url('../static/huddle/footer-pattern-mob.svg') top repeat #FF958C;
        background-size: cover;

        .content {
            gap: 4rem;
            max-width: unset;

            .title {
                flex-direction: column;
                gap: 1rem;
                text-align: center;
            }
        }

        .box {
            &.job-box, &.hire-box {
                border-radius: 1.5rem;
                padding: 2rem 1.5rem;
            }

            h3 {
                font-size: 2rem;
                white-space: normal;
            }

            .btn-container {
                gap: 1.5rem;
            }
        }

        .footer-box {
            border-radius: 1.5rem;
            padding: 3rem;

            .footer-company {
                align-items: center;
                align-self: center;

                p {
                    margin-top: 1rem;
                    text-align: center;
                }
            }

            .footer-content {
                max-width: unset;
                width: 100%;
            }

            .footer-container, .footer-links {
                flex-direction: column;

                .footer-links-column {
                    width: 100% !important;
                    margin-right: 0 !important;
                }

                h6 {
                    margin-top: 3rem;
                }
            }

            .footer-divider {
                background: rgba(126, 129, 146, 0.15);
                height: 1px;
                margin-top: 3rem;
                width: 100%;
            }

            .divider {
                margin: 3rem 0;
            }

            .footer-legal {
                > div {
                    flex-direction: column;

                    > div {
                        flex-basis: 100%;
                        flex-grow: 1;
                        max-width: 100%;
                    }
                }

                .footer-legal-links {
                    flex-direction: column;
                    gap: 1rem;
                }

                .footer-legal-prime a {
                    flex-direction: column;
                    gap: 0.5rem;
                    margin-top: 1rem;
                }

                .footer-copyright {
                    margin-top: 1rem;

                    span {
                        text-align: center;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .footer-box {
            padding: 3rem 1.5rem;

            .footer-company img {
                height: 3.5rem;
                width: 100%;
            }
        }
    }
}

.unitas-site .footer {
    background: url('../static/unitas/footer-pattern.svg') bottom no-repeat #3A84C9;
    background-size: 15.625rem;

    .box {
        background: #1A1A1A;

        h3 {
            white-space: normal;
        }

        .btn:not(.btn-secondary) {
            border-color: #C57B36;
            color: #F49944;
        }
    }

    .footer-box {
        background: #FFF;

        .footer-company {
            img {
                height: 4rem;
            }

            p {
                color: #7E8192;
            }
        }

        .footer-content {
            .footer-contact {
                svg path {
                    stroke: #C57B36;
                }

                span {
                    color: #C57B36;
                    font-weight: 600;
                }
            }

            .footer-socials svg path {
                fill: #C57B36;
                stroke: none;
            }
        }

        .footer-links {
            h6 {
                color: #1A1A1A;
                font-weight: 400;
            }

            ul {
                a, span {
                    color: #C57B36;
                    font-weight: 600;
                }

                svg path {
                    stroke: #C57B36;
                }
            }
        }

        .divider {
            background: rgba(126, 129, 146, 0.15);
        }

        .footer-legal {
            a {
                color: #C57B36;
                font-weight: 600;
            }

            .footer-legal-prime, .footer-copyright {
                a, span {
                    color: #7E8192;
                }
            }
        }
    }
}

.prime-site .footer {
    border-top: 2px solid #D3CDD0;
    background: #163BF2;

    .box {
        background: none;
        border-radius: 0;
        padding: 0;
    }

    .footer-box {
        .footer-content {
            p {
                color: #D3CDD0;
                max-width: 26rem;
            }

            .footer-contact {
                svg path {
                    stroke: #FFF;
                }

                span {
                    color: #FFF;
                    font-weight: 700;
                }
            }

            .footer-socials svg path {
                fill: #FFF;
                stroke: none;
            }
        }

        .footer-links {
            max-width: 39rem;

            h6 {
                color: #D3CDD0;
                font-family: Plus Jakarta Sans;
                font-size: 1.25rem;
                font-weight: 500;
            }

            ul a {
                color: #FFF;
                font-weight: 700;
            }
        }

        .footer-legal {
            a {
                color: #FFF;
                font-weight: 700;
            }

            .footer-copyright span {
                color: #D3CDD0;
            }
        }
    }
}
