.home, .looking-to-hire {
    .executive-search {
        background: #F9F9F9;
        overflow: hidden;

        .content {
            align-self: flex-start;
            position: relative;
            max-width: 38.5rem;

            > img {
                height: 140%;
                position: absolute;
                left: 114.5%;
                top: -15%;
            }
        }

        .text-container {
            max-width: 38.5rem;
        }

        .title {
            display: flex;
            flex-direction: column;

            > div {
                align-items: center;
                display: flex;
                flex-direction: column;
            }

            h1 {
                color: #7E6739;
                font-family: Playfair Display;
                font-size: 8rem;
                font-weight: 800;
                line-height: 8rem;
                margin: 2rem 0 1rem;
            }

            p {
                color: #7E6739;
                font-family: Plus Jakarta Sans;
                font-size: 1.25rem;
                font-weight: 600;
                letter-spacing: 1.25rem;
                text-align: center;

                &:first-child {
                    align-items: center;
                    display: flex;
                    font-size: 1rem;
                    font-weight: 400;
                    justify-content: center;
                    letter-spacing: normal;
                }
            }

            img {
                height: 1.5rem;
                margin-left: 1rem;
            }
        }

        .text {
            margin-top: 3rem;
            text-align: center;

            p {
                color: #1A1A1A;
                font-family: Plus Jakarta Sans;
                font-size: 1.125rem;
                font-weight: 400;
            }
        }

        .grid-container {
            margin-top: 2.5rem;
            margin-left: -1.5rem;
            width: calc(100% + 3rem);
        }

        .info-container {
            align-items: flex-start;
            display: flex;
            flex: 1 0 0;
            flex-direction: column;
            padding: 1rem;

            svg {
                height: unset;
                width: 2.5rem;

                path {
                    stroke: #7E6739;
                }
            }

            h5 {
                color: #1A1A1A;
                font-family: Playfair Display;
                font-weight: 900;
                margin: 1.5rem 0 1rem;
            }

            p {
                color: #1A1A1A;
                font-family: Plus Jakarta Sans;
                font-size: 1rem;
                font-weight: 400;
            }
        }

        .btn-container {
            margin-top: 4rem;
        }

        .btn {
            border-radius: 62.5rem;
            background: #7E6739;
            color: #FFF;
            font-family: Plus Jakarta Sans;
            font-size: 1.25rem;
            font-weight: 800;
        }

        @include media-breakpoint-up(md) {
            .grid-container > div {
                flex-basis: 50%;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @include media-breakpoint-down(md) {
            background: url('../static/huddle/executive-search-mob.png') top left no-repeat #F9F9F9;
            background-size: 26rem;

            .content {
                margin-top: 4rem;
                max-width: unset;

                > img {
                    display: none;
                }
            }

            .text-container {
                max-width: unset;
                text-align: center;
            }

            .title h1 {
                font-size: 6rem;
                line-height: 6rem;
                margin: 1.5rem 0 1rem;
            }

            .info-container {
                align-items: center;

                h5 {
                    font-size: 1.75rem;
                    margin: 1rem 0 0.5rem;
                }
            }

            .grid-container {
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            .title h1 {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }

            .grid-container {
                margin-top: 1.5rem;
            }

            .btn-container {
                margin-top: 2.5rem;
            }
        }
    }
}

.unitas-site {
    .home, .looking-to-hire {
        .executive-search {
            background: linear-gradient(90deg, #710058 0%, rgba(113, 0, 88, 0.92) 50%, rgba(113, 0, 88, 0.00) 70%), url('../static/unitas/executive-search.jpeg') center no-repeat, lightgray 369.127px -386.817px / 100% 143.885% no-repeat;
            background-size: cover;

            .title {
                > div {
                    flex-direction: row;
                    margin-top: 1.5rem;
                }

                h1 {
                    color: #FFF;
                    font-family: Fraunces;
                    font-size: 5rem;
                    font-style: italic;
                    font-weight: 900;
                    line-height: normal;
                    letter-spacing: -0.1rem;
                    margin: 0;
                    margin-right: 0.75rem;
                }

                p {
                    color: #FFF;
                    font-family: Fraunces;
                    font-size: 5rem;
                    font-weight: 300;
                    letter-spacing: normal;

                    &:first-child {
                        align-self: flex-start;
                        color: rgba(255, 255, 255, 0.50);
                        font-family: Poppins;
                        font-size: 1rem;
                        font-weight: 400;
                    }
                }
            }

            .text {
                text-align: left;

                p {
                    color: #FFF;
                    font-family: Poppins;
                }
            }

            .info-container {
                svg path {
                    stroke: #FFF;
                    stroke-opacity: 0.3;
                }

                h5 {
                    color: #FFF;
                    font-family: Fraunces;
                    font-weight: 400;
                }

                p {
                    color: rgba(255, 255, 255, 0.70);
                    font-family: Poppins;
                }
            }

            .btn {
                background: #FFC700;
                border-radius: 0.5rem;
                color: #4D003C;
                font-family: Poppins;
                font-weight: 600;
            }

            @include media-breakpoint-down(md) {
                background:  url('../static/unitas/executive-search-mob.jpg') top right no-repeat #710058;
                background-size: 85%;

                .title {
                    align-items: flex-start;
                    flex-direction: column-reverse;

                    div {
                        align-items: flex-start;
                        flex-direction: column;
                        margin: 0 0 1rem;

                        h1 {
                            margin: 0;
                        }
                    }

                    > p {
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;

                        img {
                            margin: 0.75rem 0 0;
                        }
                    }
                }

                .info-container h5 {
                    font-size: 2rem;
                }
            }

            @include media-breakpoint-down(sm) {
                .title {
                    h1 {
                        font-size: 3rem;
                        letter-spacing: -0.06rem;
                    }

                    div p {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}
