.our-story-static {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url('../static/huddle/our-story.jpeg') top center no-repeat, lightgray 50% / cover no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url('../static/huddle/our-story.jpeg') top center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;
    }
}

.our-process-static {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url('../static/huddle/our-process.jpeg') center no-repeat, lightgray -100% / cover no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url('../static/huddle/our-process.jpeg') center no-repeat, lightgray 773px 0px / 312.267% 100% no-repeat;
        background-size: cover;
    }
}

.our-story-static, .our-process-static {
    .content {
        align-self: center;
        margin: 2rem 0;
        max-width: 51.625rem;
        text-align: center;
    }

    h5 {
        color: #00D7A9;
    }

    .title {
        margin-top: 4rem;

        a {
            color: #F6E27F;
            background: #310A31;
        }
    }

    .text {
        margin-top: 2rem;

        p {
            color: #FFF;
            font-size: 1.125rem;
            font-weight: 600;
        }
    }

    .btn-container {
        justify-content: center;
        margin-top: 4rem;
    }

    @include media-breakpoint-down(md) {
        .title {
            h2 {
                font-size: 2.375rem;
            }
        }

        .btn-container {
            margin-top: 4.75rem;
        }
    }
}

.unitas-site {
    .our-story-static {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), url('../static/huddle/our-story.jpeg') top center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;
    }

    .our-process-static {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), url('../static/unitas/our-process.jpeg') center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;
    }

    .our-story-static, .our-process-static {
        h5 {
            color: #64A364;
            font-family: Poppins;
            font-size: 1.75rem;
            font-weight: 600;
        }

        .title a {
            background: none;
            color: #FFF;
            font-family: Fraunces;
        }

        .text p {
            font-weight: 400;
        }

        @include media-breakpoint-down(md) {
            .title h2 {
                font-size: 4rem;
            }

            .btn-container {
                margin-top: 4rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .title h2 {
                font-size: 3rem;
            }
        }
    }
}

.prime-site {
    .our-story-static {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), url('../static/huddle/our-story.jpeg') top center no-repeat, lightgray 0px -6.234px / 100% 110.145% no-repeat;
        background-size: cover;
    }

    .our-process-static {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url('../static/prime/our-process.jpeg') center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;
    }

    .our-story-static, .our-process-static {
        h5 {
            color: #00FFA3;
            font-family: Plus Jakarta Sans;
            font-weight: 700;
        }

        .text p {
            color: #D3CDD0;
            font-weight: 500;
        }

        @include media-breakpoint-down(md) {
            h2 {
                font-size: 3rem;
            }

            h5 {
                font-weight: 500;
            }
        }

        @include media-breakpoint-down(sm) {
            h5 {
                font-size: 1.25rem;
            }

            h2 {
                font-size: 2rem;
            }
        }
    }

    .our-story-home, .our-process-home {
        background: #000;

        .content {
            align-items: center;
            flex-direction: row;
            gap: 4rem;
            padding: 2rem 0;
        }

        h6 {
            color: #00FFA3;
            font-family: Plus Jakarta Sans;
            font-weight: 500;
        }

        h3 {
            margin: 2rem 0;
        }

        p {
            color: #D3CDD0;
        }

        .btn {
            margin-top: 4rem;
        }

        .img-container {
            padding: 0 3rem;

            img {
                height: 17.5625rem;
            }
        }

        @include media-breakpoint-down(lg) {
            .img-container {
                padding: 0;
            }
        }

        @include media-breakpoint-down(md) {
            .content {
                flex-direction: column-reverse !important;
            }

            .img-container img {
                height: unset;
                width: 100%;
            }
        }
    }

    .our-process-home {
        background: linear-gradient(180deg, #000 0%, #1A0223 30%, #1A0223 70%, #000 100%, #000 100%, #000 100%, #000 100%);

        .content {
            flex-direction: row-reverse;
        }
    }
}
