.contact-grid-container {
    .contact-container {
        align-items: flex-start;
        background: #310A31;
        border-radius: 2rem;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 1.75rem 1.75rem 2rem 1.75rem;
        flex-direction: column;

        .contact-text-container {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }

        .contact-icon {
            background: #FF958C;
            border-radius: 62.5rem;
            height: 4rem;
            width: 4rem;

            > svg {
                height: 100%;
                padding: 1rem;
                width: 100%;

                path {
                    stroke: #FFF;
                }
            }
        }

        .contact-title {
            margin: 1rem 0;
        }

        .contact-text {
            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
            }
        }

        .contact-info {
            margin-top: 2rem;

            p, a {
                color: #F6E27F;
                font-size: 1.25rem;
                font-weight: 800;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .contact-container {
            padding: 1.5rem;

            h5 {
                font-size: 1.75rem;
            }

            .contact-info {
                margin-top: 3rem;
            }
        }
    }
}

.executive-search .contact-grid-container .contact-container {
    background: #1A1A1A;

    .contact-icon {
        background: none;
        border-radius: 0;
        height: 2rem;
        width: 2rem;

        > svg {
            height: 2rem;
            padding: 0;
            width: 2rem;

            path {
                stroke-opacity: 0.3;
            }
        }
    }

    .contact-title {
        margin: 1.5rem 0 1rem;

        h5 {
            color: #FFF;
            font-family: Playfair Display;
            font-size: 1.75rem;
        }
    }

    .contact-text p {
        color: rgba(255, 255, 255, 0.70);
        font-family: Plus Jakarta Sans;
        font-weight: 400;
    }

    .contact-info {
        p, a {
            color: #E0B45C;
            font-family: Plus Jakarta Sans;
        }
    }
}

.unitas-site {
    .contact-grid-container .contact-container {
        background: #1A1A1A;
        border-radius: 1.5rem;

        .contact-icon {
            background: #B1C9B1;
        }

        .contact-info {
            p, a {
                color: #F49944;
                font-weight: 600;
            }
        }
    }

    .executive-search {
        .contact-grid-container .contact-container {
            background: #E0E0E0;
            border-radius: 0.5rem;

            .contact-icon {
                background: none;
                border-radius: 0;
                height: 2rem;
                width: 2rem;

                > svg {
                    height: 2rem;
                    padding: 0;
                    width: 2rem;

                    path {
                        stroke-opacity: 1;
                    }
                }
            }

            .contact-title h5 {
                color: #1A1A1A;
                font-family: Fraunces;
                font-size: 2rem;
                font-weight: 400;
            }

            .contact-text p {
                color: rgba(26, 26, 26, 0.70);
                font-family: Poppins;
            }

            .contact-info {
                p, a {
                    color: #1A1A1A;
                    font-family: Poppins;
                    font-weight: 600;
                }
            }
        }
    }
}
