.resources-nav {
  align-items: center;
  align-self: stretch;
  background: #52050A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 6rem;
  z-index: 10;

  .resources-nav-inner {
    align-items: center;
    display: flex;
    height: 4.5rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;
  }

  .resources-nav-list-container {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 1.56rem 0;
  }

  .divider {
    background: rgba(255, 255, 255, 0.15);
    height: 100%;
    margin: 0 2rem;
    width: 1px;
  }

  a {
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .resources-nav-list {
    align-items: center;
    display: flex;
    gap: 2rem;

    .active a {
      color: #FFF;
    }

    a {
      color: rgba(255, 255, 255, 0.50);
      font-size: 1.125rem;
      font-weight: 800;
    }
  }

  .resources-dropdown {
    align-items: center;
    cursor: pointer;
    display: none;
    justify-content: space-between;
    padding: 0.75rem 0.5rem;
    width: 100%;

    span {
      font-size: 1rem;
    }

    svg {
      height: unset;
      transform: rotate(0deg);
      transition: transform 0.25s linear;
      width: 0.875rem;

      path {
        stroke: #FFF;
      }
    }
  }

  &:has(.dropdown.active) {
    .resources-dropdown {
      svg {
        transform: rotate(180deg);
        transition: transform 0.25s linear;
      }
    }
  }

  .dropdown {
    background: #59060c;
    display: none;
    width: 100%;

    .dropdown-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 1.5rem;
      width: 100%;

      .resources-nav-list {
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
      }

      a {
        font-size: 1rem;
        font-weight: 800;
      }
    }
  }

  @include media-breakpoint-down(md) {
    top: 5rem;

    .resources-nav-inner {
      height: 3.5rem;
      padding: 0 0.75rem 0 0.25rem;
    }

    .resources-nav-list-container {
      display: none;
    }

    .resources-dropdown, .dropdown.active {
      display: flex;
    }
  }

  @include media-breakpoint-down(sm) {
    top: 4rem;
  }
}

.unitas-site .resources-nav {
  background: #00400E;

  .resources-nav-list a {
    font-weight: 600;
  }

  .dropdown {
    background: #004813;

    .dropdown-container .resources-nav-list a {
        font-weight: 600;
    }
  }
}
