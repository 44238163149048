.contact-us {
    > .follow-us {
        background: #FFF;

        .box-container {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 3rem;

            .box {
                align-self: stretch;
                background: #310A31;
                border-radius: 3rem;
                display: flex;
                flex-direction: row;
                gap: 4rem;
                justify-content: flex-start;
                padding: 4rem;

                &.follow {
                    background: #F6E27F;

                    h3 {
                        color: #310A31;
                        word-wrap: break-word;
                    }

                    p {
                        color: rgba(49, 10, 49, 0.70);
                    }
                }
            }
        }

        .text-container {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            max-width: 50%;

            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
            }
        }

        .action-container {
            flex-grow: 1;
            position: relative;

            &:has(.socials) {
                margin: auto 0;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 0.875rem;
            }

            a {
                color: rgba(255, 255, 255, 0.70);
                text-decoration-line: underline;
            }

            .error-msg, .success-msg {
                left: 0;
                top: -1.5rem;
            }

            .subscribe-container {
                align-items: center;
                display: flex;
                gap: 1rem;
                margin-bottom: 1rem;

                input {
                    height: 3.5rem;
                    min-width: 21.375rem;
                }

                button {
                    height: 3.875rem;
                    min-width: 10.9375rem;
                }

                @include media-breakpoint-down(lg) {
                    flex-wrap: wrap;

                    button {
                        flex-grow: 1;
                    }
                }
            }

            .socials {
                align-items: center;
                display: flex;
                justify-content: space-around;
                margin: 0 auto;
                max-width: 27rem;

                & > div {
                    align-items: center;
                    display: flex;
                    flex-grow: 1;
                    justify-content: space-around;
                    margin: 0 auto;
                }

                svg {
                    height: 2.3125rem;
                    width: unset;

                    path {
                        fill: #310A31;
                    }
                }
            }
        }

        .input-container {
            input {
                padding: 1rem 1.5rem;
            }
        }

        @include media-breakpoint-down(md) {
            .box-container {
                gap: 2rem;

                .box {
                    border-radius: 2rem;
                    flex-direction: column;
                    gap: 2.5rem;
                    padding: 2rem 1.5rem;

                    &.follow {
                        gap: 3rem;
                    }
                }
            }

            .text-container {
                max-width: unset;
            }

            .action-container {
                .subscribe-container {
                    flex-direction: column;

                    .textfield {
                        width: 100%;

                        input {
                            height: 3.75rem;
                            min-width: unset;
                            padding: 1.12rem 1.5rem;
                        }
                    }
                }

                p {
                    text-align: center;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .action-container .socials {
                flex-direction: column;
                max-width: 8.625rem;

                div {
                    //justify-content: space-between;
                    padding: 2rem 0;
                    width: 100%;

                    &:first-child {
                        padding-top: 0
                    }

                    &:last-child {
                        padding-bottom: 0
                    }
                }
            }
        }
    }
}

.unitas-site .contact-us > .follow-us .box-container {
    .box {
        &.join {
            background: #1A1A1A;
        }

        &.follow {
            background: #56422F;

            h3 {
                color: #FFF;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
            }
        }
    }

    .action-container {
        .subscribe-container input {
            height: 4rem;
        }

        .socials svg path {
            fill: #FFF;
        }
    }

    @include media-breakpoint-down(md) {
        .box {
            border-radius: 1.5rem;

            &.follow {
                padding-bottom: 4rem;
            }
        }
    }
}

.prime-site .contact-us > .follow-us {
    background: url('../static/prime/follow-us.jpeg') bottom left no-repeat #000;
    background-size: 120%;

    .box-container {
        .box {
            border-radius: 0;

            &.join {
                background: #000;

                p, a {
                    color: #D3CDD0;
                }
            }

            &.follow {
                background: #FFB800;

                h3 {
                    color: #000;
                }

                p {
                    color: rgba(0, 0, 0, 0.70);
                }
            }
        }

        .action-container {
            .subscribe-container {
                input {
                    border-radius: 0;
                    font-size: 1.125rem;
                    height: 3.6875rem;
                }

                button {
                    height: unset;
                }
            }

            .socials svg path {
                fill: #FFF;
            }
        }
    }

    @include media-breakpoint-down(md) {
        background-size: cover;
        background-position: top center;
    }
}
