.info-grid-container {
    .info-container {
        align-items: flex-start;
        background: #310A31;
        border-radius: 2rem;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 1.75rem 1.75rem 2rem 1.75rem;
        flex-direction: column;

        .info-text-container {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }

        .contact-icon {
            background: #FF958C;
            border-radius: 62.5rem;
            height: 4rem;
            width: 4rem;

            > svg {
                height: 100%;
                padding: 1rem;
                width: 100%;

                path {
                    stroke: #FFF;
                }
            }
        }

        .info-title {
            margin: 1rem 0;
            max-width: 19.5rem;
        }

        p {
            color: rgba(255, 255, 255, 0.70);
            font-size: 1rem;
        }

        .btn-container {
            margin-top: 3rem;

            a, button {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .info-container {
            padding: 1.5rem;

            .info-title {
                max-width: unset;
            }
        }
    }
}

.unitas-site {
    .info-grid-container {
        .info-container {
            background: #1A1A1A;
            border-radius: 1.5rem;

            .contact-icon {
                background: #B1C9B1;
            }
        }
    }
}
