.contact-us {
    > .contact {
        background: #310A31;

        .content {
            flex-direction: row;
            justify-content: space-between;
            gap: 8rem;
        }

        .text-container {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 2rem;
            max-width: 35rem;

            p {
                font-size: 1.125rem;
            }
        }

        .action-container {
            max-width: 35rem;
            position: relative;

            .input-container {
                input {
                    font-size: 1rem;
                    height: 3.5rem;
                    padding: 1.12rem;

                    &#message {
                        border-radius: 1rem;
                        height: 10.25rem;
                        padding-bottom: 8rem;
                    }
                }
            }

            .checkbox-container {
                align-items: center;
                display: flex;
                position: relative;

                input {
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    background: #FFF;
                    border-radius: 0.375rem;
                    cursor: pointer;
                    height: 1.25rem;
                    margin: 0;
                    width: 1.25rem;

                    &:checked {
                        appearance: auto;
                        -moz-appearance: auto;
                        -webkit-appearance: auto;
                        -o-appearance: auto;
                    }
                }

                label {
                    font-size: 1rem;
                    margin-left: 0.75rem;

                    a {
                        color: #FFF;
                        text-decoration: underline;
                    }
                }
            }

            .btn-container {
                margin-top: 3rem;
                justify-content: flex-end;

                .btn {
                    min-width: 14.0375rem;
                }
            }

            > .error-msg, > .success-msg {
                bottom: 0;
                max-width: 50%;
            }
        }

        @include media-breakpoint-down(md) {
            .content {
                flex-direction: column;
                gap: 3rem;
            }

            .text-container {
                max-width: unset;
            }

            .action-container {
                > .error-msg, > .success-msg {
                    bottom: -3.5rem;
                    max-width: unset;
                }
            }
        }
    }
}

.unitas-site .contact-us > .contact {
    background: #1A1A1A;

    .text-container {
        max-width: 37rem;
    }

    .textfield label {
        font-family: Fraunces;
    }

    @include media-breakpoint-down(md) {
        .text-container, .action-container {
            max-width: unset;
        }
    }

    @include media-breakpoint-down(sm) {
        .text-container h2 {
            font-size: 3rem;
        }
    }
}

.prime-site .contact-us > .contact {
    background: #151515;

    .text-container p {
        color: #D3CDD0;
    }

    .action-container {
        .input-container input {
            border-radius: 0;

            &#message {
                border-radius: 0;
            }
        }

        .checkbox-container {
            input {
                border-radius: 0;
            }

            label {
                font-family: Plus Jakarta Sans;
                font-weight: 500;
            }
        }

        .btn-container .btn {
            max-width: unset;
        }
    }
}
