.visit-grid-container {
    .visit-container {
        align-items: flex-start;
        background: #F6E27F;
        border-radius: 2rem;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 1.75rem 1.75rem 2rem 1.75rem;
        flex-direction: column;

        .visit-text-container {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }

        .contact-icon {
            background: #FF958C;
            border-radius: 62.5rem;
            height: 4rem;
            width: 4rem;

            > svg {
                height: 100%;
                padding: 1rem;
                width: 100%;

                path {
                    stroke: #FFF;
                }
            }
        }

        .visit-title {
            margin: 1rem 0;
            max-width: 19.5rem;

            h5 {
                color: #310A31;
            }
        }

        .visit-text p {
            color: rgba(49, 10, 49, 0.70);
            font-size: 1rem;
        }

        .visit-info {
            margin-top: 2rem;

            p, a {
                color: #310A31;
                font-size: 1.25rem;
                font-weight: 800;
                line-height: 1.75rem; /* 140% */
            }
        }
    }

    @include media-breakpoint-down(md) {
        > div {
            flex-basis: 100%;
            max-width: 70%;
        }

        .visit-container {
            padding: 1.5rem;

            .visit-title {
                max-width: unset;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        > div {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

.unitas-site .visit-grid-container .visit-container {
    background: #56422F;
    border-radius: 1.5rem;

    .contact-icon {
        background: #B1C9B1;
    }

    .visit-title h5 {
        color: #FFF;
    }

    .visit-text p {
        color: rgba(255, 255, 255, 0.70);
    }

    .visit-info {
        p, a {
            color: #FFF;
            font-weight: 600;
            line-height: 1.75rem; /* 140% */
        }
    }
}

.prime-site .visit-grid-container .visit-container {
    background: #FFB800;
    border-radius: 0;

    .contact-icon {
        background: #000;
        border-radius: 0;
    }

    .visit-title h5 {
        color: #000;
    }

    .visit-text p {
        color: rgba(0, 0, 0, 0.70);
    }

    .visit-info {
        p, a {
            color: #000;
            font-weight: 700;
        }
    }
}
