.resources-box, .resources {
  .resource-grid-item {
    max-width: 19.875rem;

    @include media-breakpoint-down(md) {
      max-width: 21.9375rem;
    }
  }

  .resource {
    border-radius: 1.5rem;
    border: 1px solid rgba(49, 10, 49, 0.15);
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 100%;
    min-width: 18.375rem;

    .resource-container {
      align-items: flex-start;
      display: flex;
      padding: 1rem 1rem 2rem;
      flex: 1 0 0;
      flex-direction: column;
      height: 100%;
    }

    .resource-type-container {
      position: relative;
      width: 100%;

      > div {
        border-radius: 1rem;
        background: #D9D9D9;
        height: 9.19rem;
        padding: 0.75rem;
      }

      img {
        border-radius: 1rem;
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }
    }

    .resource-type {
      align-items: center;
      border-radius: 6.25rem;
      display: flex;
      padding: 0.5rem 1rem;
      position: relative;
      width: fit-content;
      z-index: 5;

      &.blog {
        background: #FFE6CF;

        span {
          color: #B4651C;
        }
      }

      &.insight {
        background: #FEF7B9;

        span {
          color: #877B10;
        }
      }

      &.case-study {
        background: #EEFFCB;

        span {
          color: #516D18;
        }
      }

      span {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    .resource-title {
      flex-grow: 1;
      margin-top: 1rem;
      max-height: 6.6rem;
      overflow: hidden;

      h6 {
        color: #1A1A1A;
        font-size: 1.75rem;
        font-weight: 800;
      }
    }

    .resource-tags {
      align-items: flex-start;
      display: none;
      gap: 0.5rem;
      margin-top: 1rem;

      &.active {
          display: flex;
      }

      .resource-tag {
        align-items: center;
        background: rgba(255, 149, 140, 0.10);
        border-radius: 0.375rem;
        display: flex;
        gap: 0.375rem;
        justify-content: center;
        padding: 0.25rem 0.625rem;
        width: fit-content;

        span {
          color: #E77268;
          font-weight: 600;
          font-size: 0.875rem;
        }
      }
    }

    .resource-description {
      margin: 1.25rem 0 1.5rem;

      p {
        color: #7E8192;
        font-size: 0.875rem;
        overflow: hidden;
        max-height: 3.1875rem
      }
    }

    .btn {
      font-size: 1rem;
      padding: 0.75rem 2rem;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      .btn {
        font-size: 1rem;
        padding: 0.75rem 2rem;
      }
    }
  }
}

.unitas-site {
  .resources-box, .resources {
    .resource {
      border-radius: 0.75rem;

      .resource-container {
        padding: 1rem 1rem 1.5rem;
      }

      .resource-type-container {
        > div, img {
          border-radius: 0.5rem;
        }
      }

      .resource-tags .resource-tag {
        background: rgba(197, 123, 54, 0.10);

        span {
          color: #C57B36;
        }
      }

      .resource-description p {
        max-height: 4rem;
      }
    }
  }
}

.prime-site {
  .resources-box, .resources {
    .resource {
      background: #101010;
      border: 1px solid #747474;
      border-radius: 0;

      .resource-container {
        padding: 1rem 1rem 1.5rem;
      }

      .resource-type-container {
        img, > div {
          border-radius: 0;
        }
      }

      .resource-title {
        max-height: 6rem;

        h6 {
          color: #FFF;
          font-family: ArdelaEdgeX02-Black;
          font-size: 1.5rem;
          font-weight: 400;
        }
      }

      .resource-tags .resource-tag {
        border-radius: 62.5rem;
        background: rgba(235, 82, 249, 0.30);
        padding: 0.22rem 0.75rem 0.28rem;

        span {
          color: #F689FF;
          text-align: center;
          font-weight: 700;
        }
      }

      .resource-description p {
        color: #D3CDD0;
        max-height: 3.35rem;
      }

      .btn {
        padding: 0.69rem 2rem 0.81rem;
      }
    }
  }
}
