.home, .looking-to-hire {
    .looking-to-hire {
        background: #8762CB;
        overflow: hidden;

        .content {
            align-self: flex-start;
            position: relative;
            max-width: 38.5rem;

            > img {
                height: 170%;
                position: absolute;
                left: 114.5%;
                top: -36%;
            }
        }

        .title {
            a {
                color: #F6E27F;
                background: #310A31;
            }
        }

        .text {
            font-size: 1.125rem;
            margin-top: 2rem;

            a {
                align-items: center;
                color: #F6E27F;
                display: inline-flex;
                font-weight: 800;
                white-space: nowrap;
            }

            svg {
                height: unset;
                margin-left: 0.5rem;
                width: 1.125rem;

                path {
                    stroke: #FF958C;
                }
            }
        }

        .btn-container {
            gap: 2rem;
            margin-top: 4rem;
        }

        .btn:not(.btn-secondary) {
            background: transparent;
            border: 3px solid #F6E27F;
            color: #F6E27F;
        }

        .cv-container {
            background: #310A31;
            border-radius: 2rem;
            margin-top: 4rem;
            padding: 2rem;

            .text-container {
                max-width: 30.5rem;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
                margin: 1rem 0 2rem;
            }

            .link-container {
                display: flex;
                justify-content: flex-end;

                a {
                    align-items: center;
                    color: #F6E27F;
                    display: flex;
                    font-size: 1.25rem;
                    font-weight: 800;
                }

                svg {
                    height: unset;
                    margin-left: 1rem;
                    width: 1.25rem;

                    path {
                        stroke: #FF958C;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            background: url('../static/huddle/home-looking-to-hire-mob.png') top right no-repeat #8762CB;
            background-size: 17rem;

            .content {
                margin-top: 4rem;
                max-width: unset;

                > img {
                    display: none;
                }
            }

            .btn-container {
                margin-top: 3rem;
            }

            .cv-container {
                margin-top: 3rem;
                padding: 2rem 1.5rem;

                .text-container {
                    max-width: unset;
                }
            }
        }
    }
}

.unitas-site {
    .home, .looking-to-hire {
        .looking-to-hire {
            background: url('../static/unitas/home-looking-to-hire.jpeg') top left no-repeat;
            background-size: cover;
            margin-bottom: -1px;

            .title a {
                background: none;
                color: #FFF;
                font-family: Fraunces;
            }

            .text {
                a {
                    color: #FFF;
                    font-weight: 600;

                    svg path {
                        stroke: #FFF;
                    }
                }
            }

            .btn:not(.btn-secondary) {
                background: transparent;
                border-color: #FFF;
                color: #FFF;
            }

            .cv-container {
                background: #1A1A1A;
                border-radius: 1.5rem;

                .text-container {
                    max-width: 28.5rem;
                }

                .link-container {
                    a {
                        color: #F49944;
                    }

                    svg path {
                        stroke: #C57B36;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                background:  url('../static/unitas/home-looking-to-hire-mob.jpg') top right no-repeat #3A84C9;
                background-size: 85%;

                .content {
                    margin-top: 0;
                }

                .btn-container {
                    gap: 1.5rem;
                }
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 3rem;
                }
            }
        }
    }

    .looking-to-hire {
        .looking-to-hire {
            background: url('../static/unitas/permanent-hires.jpg') left no-repeat;
            background-size: cover;

            @include media-breakpoint-down(md) {
                background:  url('../static/unitas/permanent-hires-mob.jpg') top right no-repeat #3A84C9;
                background-size: 85%;
            }
        }
    }
}
