.our-story .origin-story {
    background: #FF958C;

    .content {
        gap: 4rem;
    }

    .title {
        h2 {
            color: #310A31;
        }
    }

    h5, h6, p, span {
        color: #310A31;
    }

    .logo {
        margin: 0 auto;
        width: 3rem;
    }

    .story {
        align-items: center;
        display: flex;
        gap: 4rem;
        justify-content: space-between;

        .text-container {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            max-width: 37rem;
        }

        &.story-1 {
            p {
                line-height: 2rem;
            }

            p:first-child {
                font-size: 1.5rem;
                line-height: 2.375rem;
            }

            h6 {
                font-size: 1.5rem;
                font-weight: 800;
                line-height: 2rem;
            }
        }

        &.story-2, &.story-3 {
            p {
                font-size: 1.125rem;
                line-height: 2rem;
            }

            h6 {
                font-size: 1.5rem;
                font-weight: 800;
                line-height: 2rem;
            }
        }

        .img-container {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;

            > div {
                align-items: center;
                background: #F6E27F;
                border-radius: 62.5rem;
                display: flex;
                height: 31.25rem;
                justify-content: center;
                padding: 7px;
                width: 31.25rem;
            }

            img {
                border-radius: 62.5rem;
                background: #D9D9D9;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            span {
                text-align: center;
                font-size: 0.875rem;
                line-height: 1.5rem; /* 171.429% */
            }
        }
    }

    .quote {
        padding: 2rem 6rem;

        h3 {
            color: #8762CB;
            text-align: center;
            text-shadow: 3px 6px #F6E27F;
            font-weight: 800;
        }
    }

    @include media-breakpoint-down(md) {
        .content {
            gap: 3rem;
        }

        .story {
            flex-direction: column;
            gap: 2rem;

            &.story-2 {
                flex-direction: column-reverse;
            }

            .img-container > div {
                padding: 6px;
                max-height: 21.25rem;
                max-width: 21.26rem;
            }
        }

        .quote {
            padding: 2rem 0;
        }
    }

    @include media-breakpoint-down(sm) {
        .story {
            .img-container > div {
                padding: 5px;
                max-height: 17.4375rem;
                max-width: 17.4375rem;
            }
        }
    }
}

.unitas-site .our-story .origin-story {
    background: #FFF;

    .title h2 {
        color: #1A1A1A;
    }

    h5, h6, p, span {
        color: #1A1A1A;
        font-family: Poppins;
    }

    .story {
        &.story-1 p:first-child {
            color: #7E8192;
        }

        h6 {
            font-weight: 600;
        }

        .img-container {
            div {
                background: linear-gradient(#ED903A, #F8B535)
            }

            span {
                font-weight: 600;
            }
        }
    }

    .quote h3 {
        color: #3A84C9;
        font-family: Fraunces;
        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-shadow: none;
    }

    @include media-breakpoint-down(sm) {
        .title h2 {
            font-size: 3rem;
        }

        .quote h3 {
            font-size: 2rem;
        }
    }
}

.prime-site .our-story .origin-story {
    background: #151515;

    .title h2 {
        color: #FFF;
    }

    h6 {
        color: #D3CDD0;
        font-family: Plus Jakarta Sans;
        font-size: 1.5rem;
        font-weight: 700;
    }

    p {
        color: rgba(211, 205, 208, 0.70);
    }

    .story.story-1 p:first-child {
        color: #D3CDD0;
    }

    .quote h3 {
        color: #FFF;
        font-weight: 400;
        text-shadow: none;
    }

    .img-container > div {
        border-radius: 0;
        height: 17.5625rem;
        overflow: hidden;
        padding: 0;
        max-width: unset;

        img {
            border-radius: 0;
        }
    }

    @include media-breakpoint-down(md) {
        .img-container > div {
            height: unset;
            max-height: unset;
            width: 100%;
        }
    }
}
