.leadership-team .leaders {
    background: #8762CB;

    .inner-container {
        padding: 3rem 1.5rem;
    }

    .leader {
        align-items: center;
        display: flex;
        gap: 4rem;
        justify-content: space-between;
        padding: 3rem 0;

        &:nth-child(2) {
            flex-direction: row-reverse;
        }

        .img-container {
            align-items: center;
            display: flex;
            flex-grow: 1;
            justify-content: center;

            > div {
                align-items: center;
                background: #02C39A;
                border-radius: 62.5rem;
                display: flex;
                height: 31.25rem;
                justify-content: center;
                padding: 7px;
                width: 31.25rem;
            }

            img {
                border-radius: 62.5rem;
                background: #000;
                height: 100%;
                width: 100%;
            }
        }
    }

    .text-container {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        max-width: 37rem;
    }

    .role {
        margin: 1rem 0 3rem;

        span {
            color: rgba(255, 255, 255, 0.70);
            font-size: 1.75rem;
        }
    }

    .text {
        display: flex;
        align-self: flex-start;
        margin-bottom: 4rem;
        max-width: 45.0625rem;

        p {
            color: #FFF;
            font-size: 1.125rem;
        }
    }

    .leader-contact {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        & > div {
            align-items: center;
            display: flex;
        }

        a {
            align-items: center;
            display: flex;
        }

        svg {
            height: unset;
            width: 1.5rem;

            path {
                stroke: #FF958C;
            }
        }

        div:last-child svg path {
            fill: #FF958C;
            stroke: none;
        }

        span {
            color: #F6E27F;
            font-size: 1.25rem;
            font-weight: 800;
            margin-left: 1rem;
        }
    }

    @include media-breakpoint-down(md) {
        .inner-container {
            padding: 2rem 0.75rem;
        }

        .leader {
            flex-direction: column-reverse;
            gap: 2rem;
            padding: 2rem 0;

            &:nth-child(2) {
                flex-direction: column-reverse;
            }

            .img-container > div {
                padding: 6px;
                max-height: 21.25rem;
                max-width: 21.25rem;
            }
        }

        .role {
            margin-bottom: 2rem;
        }

        .text {
            margin-bottom: 3rem;
            max-width: unset;
        }
    }

    @include media-breakpoint-down(sm) {
        .leader {
            .img-container > div {
                padding: 5px;
                max-height: 17.4375rem;
                max-width: 17.4375rem;
            }
        }
    }
}

.unitas-site .leadership-team .leaders {
    background: #3A84C9;

    .leader {
        h2 {
            color: #1A1A1A;
            font-size: 3rem;
        }

        .role span {
            font-size: 2rem;
        }

        .img-container > div {
            background: linear-gradient(#ED903A, #F8B535);
        }
    }

    .leader-contact {
        span {
            color: #FFF;
            font-weight: 600;
        }

        svg path {
            stroke: #FFF;
        }

        div:last-child svg path {
            fill: #FFF;
            stroke: none;
        }
    }
}

.prime-site .leadership-team .leaders {
    background: #163BF2;

    .leader {
        .role span {
            color: rgba(211, 205, 208, 0.70);
            font-size: 2rem;
            font-weight: 500;
        }

        .text p {
            color: #D3CDD0;
        }

        .img-container > div {
            background: #000;
            border-radius: 0;
            height: 17.5625rem;
            overflow: hidden;
            padding: 0;

            img {
                background: #7E8192;
                border-radius: 0;
                height: unset;
            }
        }
    }

    .leader-contact {
        svg path {
            stroke: #FFF;
        }

        div:last-child svg path {
            fill: #FFF;
            stroke: none;
        }

        span {
            color: #FFF;
            font-weight: 700;
        }
    }

    @include media-breakpoint-down(md) {
        .leader .img-container > div {
            height: unset;
            max-height: unset;
            max-width: unset;
            width: 100%;
        }
    }
}