.specialism,
.looking-for-a-job,
.our-story,
.our-process,
.contact-us,
.executive-search,
.embedded-talent,
.contract-interim,
.permanent-hires,
.home,
.company {
    .specialists {
        background: #8762CB;

        .text-container {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            margin-bottom: 4rem;
            max-width: 45.0625rem;
        }

        .text {
            display: flex;
            align-self: flex-start;
            margin-top: 2rem;

            p {
                color: #FFF;
                font-size: 1.125rem;
            }
        }

        .specialists-container {
            align-items: flex-start;
            display: flex;
            gap: 2rem;
            justify-content: space-around;

            .specialist {
                align-items: center;
                display: flex;
                flex-direction: column;
                max-width: 20rem;
                width: 100%;

                .specialist-img-container {
                    align-items: center;
                    background: #02C39A;
                    border-radius: 62.5rem;
                    display: flex;
                    height: 15.625rem;
                    justify-content: center;
                    padding: 5px;
                    width: 15.625rem;

                    img {
                        background: #000;
                        border-radius: 62.5rem;
                        height: 100%;
                        width: 100%;
                    }
                }

                .specialist-details {
                    align-self: stretch;
                }

                h5 {
                    font-weight: 800;
                    margin: 1.5rem 0 0.5rem;
                }

                span {
                    color: rgba(255, 255, 255);
                    font-size: 1.125rem;
                }

                p {
                    color: rgba(255, 255, 255, 0.70);
                    font-size: 1rem;
                    margin: 1rem 0 1.5rem;
                }

                .specialist-contact {
                    align-items: flex-start;
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    & > div {
                        align-items: center;
                        display: flex;
                    }

                    a {
                        align-items: center;
                        display: flex;
                    }

                    svg {
                        height: unset;
                        width: 1.25rem;

                        path {
                            stroke: #FF958C;
                        }
                    }

                    span {
                        color: #F6E27F;
                        font-size: 1.125rem;
                        font-weight: 800;
                        margin-left: 0.75rem;
                    }

                    div:last-child svg path {
                        fill: #FF958C;
                        stroke: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .text-container {
                margin-bottom: 2rem;
            }

            .specialists-container {
                flex-direction: column;
                gap: 1rem;

                .specialist {
                    max-width: unset;
                    padding: 1rem 0;

                    .specialist-img-container {
                        height: 21.25rem;
                        width: 21.25rem;
                    }

                    h5 {
                        font-size: 1.75rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .title h2 {
                font-size: 2.5rem;
            }

            .specialists-container .specialist .specialist-img-container {
                height: 15.625rem;
                width: 15.625rem;
            }
        }
    }
}

.executive-search {
    > .specialists {
        background: #F9F9F9;

        .title h2 {
            color: #1A1A1A;
            font-family: Playfair Display;
            font-weight: 400;
        }

        .text p {
            color: #1A1A1A;
            font-family: Plus Jakarta Sans;
            font-weight: 400;
        }

        .specialists-container {
            .specialist {
                .specialist-img-container {
                    background: #7E6739;
                    padding: 3px;
                }

                h5 {
                    color: #1A1A1A;
                    font-family: Playfair Display;
                    font-weight: 900;
                }

                span {
                    color: rgba(26, 26, 26);
                    font-family: Plus Jakarta Sans;
                }

                p {
                    color: rgba(26, 26, 26, 0.70);
                    font-family: Plus Jakarta Sans;
                    font-weight: 400;
                }

                .specialist-contact {
                    span {
                        color: #7E6739;
                        font-family: Plus Jakarta Sans;
                    }

                    svg path {
                        stroke: #7E8192;
                    }

                    div:last-child svg path {
                        fill: #7E8192;
                        stroke: none;
                    }
                }
            }
        }
    }
}

.unitas-site {
    .specialism,
    .looking-for-a-job,
    .our-story,
    .our-process,
    .contact-us,
    .executive-search,
    .embedded-talent,
    .contract-interim,
    .permanent-hires {
        .specialists {
            background: #3A84C9;

            .specialists-container .specialist {
                .specialist-img-container {
                    background: linear-gradient(#ED903A, #F8B535);
                }

                h5 {
                    color: #1A1A1A;
                    font-size: 2rem;
                    font-weight: 400;
                }

                .specialist-contact {
                    span {
                        color: #FFF;
                        font-weight: 600;
                    }

                    svg path {
                        stroke: #FFF;
                    }

                    div:last-child svg path {
                        fill: #FFF;
                        stroke: none;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 3rem;
                }
            }
        }
    }

    .executive-search > .specialists {
        background: #710058;

        .title h2 {
            color: #FFF;
            font-family: Fraunces;
        }

        .text p {
            color: #FFF;
            font-family: Poppins;
        }

        .specialists-container .specialist {
            .specialist-img-container {
                background: #FFC700;
            }

            h5 {
                color: #FFF;
                font-family: Fraunces;
                font-weight: 400;
            }

            span {
                color: rgba(255, 255, 255);
                font-family: Poppins;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-family: Poppins;
            }

            .specialist-contact span {
                color: #FFC700;
            }
        }
    }
}

.prime-site {
    .home .specialists {
        background: linear-gradient(180deg, #000 0%, #1A0223 30%, #1A0223 70%, #000 100%, #000 100%, #000 100%, #000 100%);
    }

    .specialists {
        background: #000;

        .text-container {
            align-self: center;
            max-width: 51.625rem;
            text-align: center;
        }

        .title h2 {
            font-family: ArdelaEdgeX02-Medium;
            font-size: 3.5rem;
            font-weight: 400;
        }

        .text p {
            color: #D3CDD0;
            font-family: Plus Jakarta Sans;
            font-weight: 500;
        }

        .specialists-container {
            .specialist {
                align-items: flex-start;

                .specialist-img-container {
                    background: #000;
                    border-radius: 0;
                    padding: 0;

                    img {
                        background: #7E8192;
                        border-radius: 0;
                    }
                }

                .specialist-details {
                    h5 {
                        font-family: ArdelaEdgeX02-Black;
                        font-weight: 400;
                    }

                    span {
                        color: rgba(211, 205, 208);
                    }
                }

                p {
                    color: rgba(211, 205, 208, 0.7);
                }

                .specialist-contact {
                    span {
                        color: #FFF;
                        font-weight: 700;
                    }

                    svg path {
                        stroke: #FFF;
                    }

                    div:last-child svg path {
                        fill: #FFF;
                        stroke: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .specialists-container {
                .specialist {
                    align-items: center;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .title h2 {
                font-size: 2.5rem;
            }
        }

    }

    .home .specialists .content {
        padding: 2rem 0;
    }
}
