.specialism, .looking-to-hire {
    > .specialisms {
        background: #310A31;

        .content {
            gap: 4rem;
        }

        &.disciplines {
            background: #8762CB;
        }

        &.sectors {
            background: #F9F9F9;

            h2 {
                color: #310A31;
            }

            h5 {
                color: #7E8192;
            }

            .text {
                p {
                    color: #7E8192;
                }
            }

            .specialism-list-container {
                .specialism-list {
                    height: 41rem;

                    a {
                        color: #310A31;
                        width: 15.5rem;
                    }
                }
            }
        }

        .text-container {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
        }

        .text {
            display: flex;
            align-self: flex-start;
            margin-top: 2rem;
            max-width: 45.0625rem;

            p {
                color: #FFF;
                font-size: 1.125rem;
            }
        }

        .specialism-list-container {
            margin: 0 2rem;

            .specialism-list {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 2rem;
                height: 24.75rem;

                h5 {
                    font-weight: 600;
                    margin-top: 1rem;
                }

                > div {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                }

                a {
                    align-items: center;
                    color: #F6E27F;
                    display: flex;
                    font-size: 1.25rem;
                    font-weight: 800;
                    width: 22rem;
                }

                svg {
                    height: unset;
                    margin-left: 1rem;
                    width: 1.25rem;

                    path {
                        stroke: #FF958C;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .specialism-list-container .specialism-list {
                height: 41rem;
            }

            &.sectors .specialism-list-container .specialism-list {
                height: 54rem;
            }
        }

        @include media-breakpoint-down(md) {
            .content {
                gap: 3rem;
            }

            .specialism-list-container,
            &.sectors .specialism-list-container {
                margin: 0;

                .specialism-list {
                    height: unset;

                    h5 {
                        margin-top: 0;
                    }

                    a {
                        width: 26rem;
                    }
                }
            }

            &.sectors .specialism-list-container .specialism-list {
                height: unset;
            }
        }

        @include media-breakpoint-down(sm) {
            .specialism-list-container,
            &.sectors .specialism-list-container {
                .specialism-list {
                    flex-direction: column;

                    > div {
                        width: 100%;
                    }

                    a {
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.unitas-site {
    .specialism, .looking-to-hire {
        > .specialisms {
            background: #1A1A1A;

            &.sectors {
                background: #F9F9F9;

                h2 {
                    color: #1A1A1A;
                }

                .specialism-list-container {
                    .specialism-list {
                        height: 34.5rem;

                        a {
                            color: #1A1A1A;
                        }

                        svg path {
                            stroke: #1A1A1A;
                        }
                    }
                }
            }

            .specialism-list-container {
                .specialism-list {
                    height: 19.5rem;

                    h5 {
                        font-weight: 400;
                    }

                    a {
                        color: #F49944;
                        font-weight: 600;
                    }

                    svg path {
                        stroke: #C57B36;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .specialism-list-container .specialism-list {
                    height: 30.5rem;
                }
            }

            @include media-breakpoint-down(md) {
                .specialism-list-container .specialism-list,
                &.sectors .specialism-list-container .specialism-list {
                    height: unset;
                }
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 3rem;
                }
            }
        }
    }
}
