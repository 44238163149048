.our-story,
.our-process,
.executive-search,
.embedded-talent,
.contract-interim,
.permanent-hires,
.corporate,
.diversity {
    .pro-points {
        background: #310A31;

        .text-container {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            max-width: 45.0625rem;
        }

        .text {
            margin-top: 2rem;

            p {
                font-size: 1.125rem;
            }
        }

        .pro-point-container {
            overflow: hidden;
            margin-top: 4rem;
            text-align: center;

            .pro-point {
                display: flex;
                flex-direction: column;
                max-width: 23.3333rem;

                .pro-point-icon {
                    background: #02C39A;
                    border-radius: 62.5rem;
                    height: 4rem;
                    margin: 0 auto;
                    width: 4rem;

                    > img {
                        height: 100%;
                        padding: 1rem;
                        width: 100%;
                    }
                }

                h5 {
                    margin: 1.5rem 0 1rem;
                }

                p {
                    color: rgba(255, 255, 255, 0.70);
                    font-size: 1rem;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .pro-point-container {
                margin-top: 3rem;

                .pro-point {
                    max-width: unset;

                    h5 {
                        font-size: 1.75rem;
                        margin: 1rem 0 0.5rem;
                    }
                }
            }
        }
    }
}

.embedded-talent,
.contract-interim,
.permanent-hires,
.corporate,
.diversity {
    .pro-points .pro-point-container .pro-point .pro-point-icon {
        background: #FF958C;
    }
}

@include media-breakpoint-down(sm) {
    .corporate, .diversity {
        .pro-points .title h2 {
            font-size: 2.375rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .permanent-hires, .our-story, .our-process, .corporate, .diversity {
        .pro-points .pro-point-container > div > div {
            flex-basis: 33.333333%;
            flex-grow: 0;
            max-width: 33.333333%;
        }
    }

    .executive-search, .contract-interim, .embedded-talent,
    .unitas-site .our-process,
    .unitas-site .permanent-hires {
        .pro-points .pro-point-container > div > div {
            flex-basis: 50%;
            flex-grow: 0;
            max-width: 50%;
        }
    }
}

.executive-search {
    .pro-points {
        background: #645028;

        .title h2 {
            font-family: Playfair Display;
            font-weight: 400;
        }

        .text p {
            color: #FFF;
            font-family: Plus Jakarta Sans;
            font-weight: 400;
        }

        .pro-point-container .pro-point {
            .pro-point-icon {
                background: #7E8192;
            }

            h5 {
                font-family: Playfair Display;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-family: Plus Jakarta Sans;
                font-weight: 400;
            }
        }
    }
}

.unitas-site {
    .our-story,
    .our-process,
    .embedded-talent,
    .contract-interim,
    .permanent-hires,
    .corporate,
    .diversity {
        .pro-points {
            background: #1A1A1A;

            .pro-point-container .pro-point {
                .pro-point-icon {
                    background: #B1C9B1;
                }

                h5 {
                    font-size: 2rem;
                }
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 3rem;
                }
            }
        }
    }

    .executive-search .pro-points {
        background: #FFC700;

        .title h2,
        .text p {
            color: #1A1A1A;
        }

        .pro-point-container .pro-point {
            .pro-point-icon {
                background: #710058;
            }

            h5 {
                color: #1A1A1A;
            }

            p {
                color: rgba(26, 26, 26, 0.70);
            }
        }

        @include media-breakpoint-down(sm) {
            .title h2 {
                font-size: 3rem;
            }
        }
    }
}

.prime-site .pro-points {
    background: #163BF2;

    .text p {
        color: #D3CDD0;
    }

    .pro-point-container .pro-point {
        .pro-point-icon {
            background: #000;
            border-radius: 0;
        }

        h5 {
            font-family: ArdelaEdgeX02-Black;
        }

        p {
            color: #D3CDD0;
        }
    }
}