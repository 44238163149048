.jobs {
    .title {
        margin-bottom: 2rem;

        h2 {
            color: #310A31;
        }
    }

    .text {
        font-size: 1.125rem;
        margin-top: 2rem;

        a {
            color: #F6E27F;
            font-weight: 800;
        }

        img {
            margin-left: 0.5rem;
        }
    }

    .jobs-grid {
        margin-top: 4rem;

        > div:first-child {
            margin-bottom: 1.5rem;

            > span {
                color: #7E8192;
                font-size: 1.125rem;
            }
        }

        > div:last-child {
            justify-content: flex-start;
        }
    }

    @include media-breakpoint-down(sm) {
        .jobs-grid > div:last-child {
            justify-content: center;
        }
    }
}

.unitas-site .jobs {
    .title h2 {
        color: #1A1A1A;
        font-weight: 900;
    }

    @include media-breakpoint-down(sm) {
        .title h2 {
            font-size: 3rem;
        }
    }
}
