.specialism,
.looking-to-hire,
.looking-for-a-job,
.executive-search,
.embedded-talent,
.contract-interim,
.permanent-hires {
    .specialism-hero {
        .text-container {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            width: 45.0625rem;
        }

        .title {
            h1 {
                font-size: 5rem;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            margin-top: 3rem;
            max-width: 37.0625rem;

            p {
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 2rem;
            }

            .btn-container {
                margin-top: 4rem;
            }
        }

        .info-grid-container,
        .contact-grid-container {
            margin-top: 5.25rem;
        }

        @include media-breakpoint-down(md) {
            .text-container {
                width: unset;
            }

            .title {
                h1 {
                    font-size: 4rem;
                }
            }

            .text {
                margin-top: 2rem;
                max-width: unset;
            }

            .info-grid-container,
            .contact-grid-container {
                margin-top: 2.25rem;

                > div {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .info-grid-container,
            .contact-grid-container {
                > div {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

.permanent-hires .specialism-hero .title {
    h1 {
        font-size: 4.5rem;
    }
}

@include media-breakpoint-down(sm) {
    .embedded-talent, .contract-interim, .permanent-hires {
        .specialism-hero .title {
            h1 {
                font-size: 3.5rem;
            }
        }
    }
}

.looking-to-hire,
.looking-for-a-job,
.embedded-talent,
.contract-interim,
.permanent-hires {
    .specialism-hero {
        background: url('../static/huddle/looking-to-hire.png') top -5rem right -5rem no-repeat #8762CB;
        background-size: clamp(35rem, 48%, 50rem) auto;

        .title {
            margin-top: 2rem;

            a {
                color: #F6E27F;
                background: #310A31;
            }
        }

        @include media-breakpoint-down(md) {
            background: url('../static/huddle/looking-to-hire.png') top -1.5rem right -1.5rem no-repeat #8762CB;
            background-size: clamp(14rem, 48%, 21rem) auto;

            .title {
                margin-top: 4rem;
                max-width: 28rem;
            }
        }
    }
}

.looking-for-a-job .specialism-hero {
    background-image: url('../static/huddle/looking-for-a-job.png');
}

.embedded-talent .specialism-hero {
    background-image: url('../static/huddle/embedded-talent-hero.png');
}

.contract-interim .specialism-hero {
    background-image: url('../static/huddle/contract-hires-hero.png');
}

.permanent-hires .specialism-hero {
    background-image: url('../static/huddle/permanent-hires-hero.png');
}

.specialism {
    .specialism-hero {
        background: url('../static/huddle/specialism.png') top right no-repeat #FFF;
        background-size: clamp(35rem, 48%, 50rem) auto;

        &.sectors-hero {
            background: url('../static/huddle/sector.png') top -5rem right -5rem no-repeat #FFF;
            background-size: clamp(35rem, 48%, 50rem) auto;
        }

        .inner-container {
            padding: 5rem 1.5rem 6rem;
        }

        .title {
            padding-top: 7rem;
            position: relative;

            .specialism-icon {
                border-radius: 62.5rem;
                border: 6px solid #FF958C;
                height: 12rem;
                left: 50%;
                position: absolute;
                top: 0;
                width: 12rem;

                img {
                    height: 100%;
                    padding: 3rem;
                    width: 100%;
                }
            }

            h1 {
                color: #310A31;
                position: relative;
                z-index: 2;
            }

            .loader {
                justify-content: flex-start;
            }
        }

        .text {
            p {
                color: #7E8192;
            }
        }

        @include media-breakpoint-down(md) {
            background: #FFF;

            &.sectors-hero {
                background: #FFF;
            }

            .inner-container {
                padding: 1.5rem 0.75rem 4rem;
            }

            .title {
                padding-top: 6.5rem;

                .specialism-icon {
                    left: unset;
                    right: 0;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .title {
                .specialism-icon {
                    height: 10rem;
                    width: 10rem;

                    img {
                        padding: 2rem;
                    }
                }

                h1 {
                    font-size: 2.5rem;
                }
            }
        }
    }
}

.executive-search {
    .specialism-hero {
        background: #F9F9F9;
        overflow: hidden;

        .content {
            position: relative;
            margin-top: 2rem;

            .text-container {
                width: 45.0625rem;

                > img {
                    height: 175%;
                    position: absolute;
                    left: 65%;
                    top: -35%;
                    z-index: -5;
                }
            }
        }

        .title {
            max-width: 35.625rem;

            > h1 {
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1.5rem;
                }

                h1 {
                    color: #7E6739;
                    font-family: Playfair Display;
                    font-size: 8rem;
                    font-weight: 800;
                    line-height: 8rem;
                    margin-bottom: 1.5rem;
                }

                p {
                    color: #7E6739;
                    font-family: Plus Jakarta Sans;
                    font-size: 1.25rem;
                    font-weight: 600;
                    letter-spacing: 1.25rem;
                    text-align: center;
                }

                > p {
                    align-items: center;
                    display: flex;
                    font-size: 1rem;
                    font-weight: 400;
                    justify-content: center;
                    letter-spacing: normal;
                }

                img {
                    height: 1.5rem;
                    margin-left: 1rem;
                }
            }
        }

        .text {
            align-self: flex-end;

            p {
                color: #1A1A1A;
                font-family: Plus Jakarta Sans;
                font-weight: 400;
            }
        }

        @include media-breakpoint-down(lg) {
            .text {
                align-self: flex-start;
            }
        }

        @include media-breakpoint-down(md) {
            background: url('../static/huddle/executive-search-mob.png') top left no-repeat #F9F9F9;
            background-size: 26rem;

            .content {
                margin-top: 4rem;

                .text-container {
                    text-align: center;
                    width: unset;

                    > img {
                        display: none;
                    }
                }
            }

            .title {
                max-width: unset;

                > h1 h1 {
                    font-size: 6rem;
                    line-height: 6rem;
                    margin-bottom: 1rem;
                }
            }

            .text {
                margin-top: 3rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .title > h1 h1 {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }

            .text p {
                font-size: 1.125rem;
            }
        }
    }
}

.unitas-site {
    .looking-to-hire,
    .looking-for-a-job,
    .embedded-talent,
    .contract-interim,
    .permanent-hires {
        .specialism-hero {
            background: #3A84C9;
            overflow: hidden;

            .text-container {
                position: relative;

                > img {
                    position: absolute;
                    left: 105%;
                    top: -18rem;
                    width: 62.5rem;
                    z-index: -5;
                }
            }

            .title {
                h1, a {
                    background: none;
                    color: #FFF;
                    font-family: Fraunces;
                    font-size: 6rem;
                    font-weight: 900;
                }
            }

            .text p {
                color: #FFF;
                font-weight: 400;
            }

            @include media-breakpoint-down(md) {
                background: #3A84C9;

                .text-container > img {
                    display: none;
                }

                .text {
                    margin-top: 2.5rem;
                }
            }

            @include media-breakpoint-down(sm) {
                .title {
                    h1, a {
                        font-size: 4rem;
                    }
                }
            }
        }
    }

    .looking-for-a-job, .contract-interim, .permanent-hires {
        .specialism-hero .title {
            h1 {
                font-size: 5rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .looking-for-a-job, .contract-interim {
            .specialism-hero .title h1 {
                font-size: 4rem;
            }
        }

        .permanent-hires .specialism-hero .title h1 {
            font-size: 3rem;
        }
    }

    .looking-for-a-job .specialism-hero .title {
        max-width: 40rem;
    }

    .specialism .specialism-hero {
        background: #F9F9F9;
        overflow: hidden;

        .text-container {
            position: relative;

            > img {
                position: absolute;
                left: 105%;
                top: -18rem;
                width: 62.5rem;
                z-index: -5;
            }
        }

        .title {
            .specialism-icon {
                border-color: #C57B36;
            }

            h1 {
                color: #1A1A1A;
                font-size: 6rem;
                font-weight: 900;
            }
        }

        .text p {
            font-weight: 400;
        }

        @include media-breakpoint-down(md) {
            background: #F9F9F9;

            .inner-container {
                padding: 1.5rem 0.75rem 4rem;
            }

            .text-container > img {
                display: none;
            }

            .title {
                background-position: right 0;
                padding-top: 6.5rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .title {
                background-size: 10rem;

                h1 {
                    font-size: 3rem;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .looking-to-hire .specialism-hero {
            background: url('../static/unitas/looking-to-hire-mob.png') top right no-repeat #3A84C9;
            background-size: 18.6875rem;
        }

        .looking-for-a-job .specialism-hero {
            background: url('../static/unitas/candidates-mob.png') top right no-repeat #3A84C9;
            background-size: 18.6875rem;
        }

        .embedded-talent .specialism-hero {
            background: url('../static/unitas/embedded-talent-mob.png') top right no-repeat #3A84C9;
            background-size: 18.6875rem;
        }

        .contract-interim .specialism-hero {
            background: url('../static/unitas/contract-hires-mob.png') top right no-repeat #3A84C9;
            background-size: 18.6875rem;
        }

        .permanent-hires .specialism-hero {
            background: url('../static/unitas/permanent-hires-mob.png') top right no-repeat #3A84C9;
            background-size: 18.6875rem;
        }
    }

    .executive-search .specialism-hero {
        background: linear-gradient(90deg, #710058 0%, rgba(113, 0, 88, 0.92) 60%, rgba(113, 0, 88, 0.00) 100%), url('../static/unitas/executive-search.jpeg') right no-repeat, lightgray 369.127px -386.817px / 100% 143.885% no-repeat;
        background-size: cover;

        .title {
            display: flex;
            flex-direction: column;
            max-width: unset;

            > h1 {
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    flex-direction: row;
                    margin-top: 1.5rem;
                }

                h1 {
                    color: #FFF;
                    font-family: Fraunces;
                    font-size: 5rem;
                    font-style: italic;
                    font-weight: 900;
                    line-height: normal;
                    letter-spacing: -0.1rem;
                    margin: 0;
                    margin-right: 0.75rem;
                }

                p {
                    color: #FFF;
                    font-family: Fraunces;
                    font-size: 5rem;
                    font-weight: 300;
                    letter-spacing: normal;
                }

                > p {
                    align-self: flex-start;
                    color: rgba(255, 255, 255, 0.50);
                    font-family: Poppins;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }

        .text {
            text-align: left;

            p {
                color: #FFF;
                font-family: Poppins;
            }
        }

        .info-container {
            h5 {
                color: #FFF;
                font-family: Fraunces;
                font-weight: 400;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-family: Poppins;
            }
        }

        .btn {
            background: #FFC700;
            border-radius: 0.5rem;
            color: #4D003C;
            font-family: Poppins;
            font-weight: 600;
        }

        @include media-breakpoint-down(md) {
            background:  url('../static/unitas/executive-search-mob.jpg') top right no-repeat #710058;
            background-size: 85%;

            .content {
                margin: 0;
            }

            .title {
                > h1 {
                    align-items: flex-start;
                    flex-direction: column-reverse;

                    > div {
                        align-items: flex-start;
                        flex-direction: column;
                        margin: 0 0 1rem;

                        h1 {
                            margin: 0;
                        }
                    }

                    > p {
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;

                        img {
                            margin: 0.75rem 0 0;
                        }
                    }
                }
            }

            .text {
                margin-top: 2.5rem;
            }

            .info-container h5 {
                font-size: 2rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .title {
                > h1 h1 {
                    font-size: 4rem;
                    letter-spacing: -0.06rem;
                }

                div p {
                    font-size: 4rem;
                }
            }
        }
    }
}
