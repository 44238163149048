.top-nav {
  align-items: center;
  align-self: stretch;
  background: #310A31;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 20;

  .top-nav-inner {
    align-items: center;
    display: flex;
    height: 3.5rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;
  }

  .top-nav-list {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 1.09rem 0;
    width: 100%;
  }

  .top-nav-quicklinks {
    align-items: center;
    cursor: pointer;
    display: none;
    justify-content: space-between;
    padding: 0.75rem 0.5rem;
    width: 100%;

    span {
      color: #F6E27F;
      font-size: 1rem;
    }

    svg {
      height: unset;
      transform: rotate(0deg);
      transition: transform 0.25s linear;
      width: 0.875rem;

      path {
        stroke: #F6E27F;
      }
    }
  }

  &:has(.dropdown.active) {
    .top-nav-quicklinks {
      svg {
        transform: rotate(180deg);
        transition: transform 0.25s linear;
      }
    }
  }

  .top-nav-left, .top-nav-right {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;

    > div {
      align-items: center;
      display: flex;
    }

    a {
      color: #F6E27F;
      font-size: 0.875rem;
      font-weight: 700;
    }

    .divider {
      background: rgba(255, 255, 255, 0.15);
      height: 100%;
      width: 1px;
    }
  }

  .dropdown {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #310A31;
    display: none;
    width: 100%;

    .dropdown-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 2rem 1.5rem;
      width: 100%;

      .top-nav-left, .top-nav-right {
        flex-direction: column;
        gap: 1.5rem;

        a {
          font-size: 1rem;
          font-weight: 800;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .top-nav-inner {
      height: 2.75rem;
      padding: 0 0.75rem 0 0.25rem;
    }

    .top-nav-list {
      display: none;
    }

    .top-nav-left, .top-nav-right {
      align-items: flex-start;

      .divider {
        display: none;
      }
    }

    .top-nav-quicklinks {
      display: flex;
      padding: 0.625rem 0.5rem;
    }

    .dropdown.active {
      display: flex;
    }
  }
}

@include media-breakpoint-down(md) {
  body:has(.sidebar.active) {
    overflow: hidden;
  }
}

.header-outer-container {
  background: #FFF;
  border-bottom: 1px solid rgba(49, 10, 49, 0.15);
  position: sticky;
  top: 0;
  z-index: 20;

  @media screen and (min-width: 868px) {
    &.header-home {
      align-items: center;
      background: none;
      border-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 7.5rem;
      width: 100%;

      .header-nav {
        background: #FFF;
        border-radius: 62.5rem;
        padding: 1.75rem 3rem;
      }
    }
  }

  .header-inner-container {
    display: flex;
    padding: 0 1.5rem;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 0 0.75rem;
    }
  }
}

.header {
  align-items: center;
  display: flex;
  height: 6rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;

  &.header-home {
    border-radius: 62.5rem;
    width: 100%;
  }

  .header-container {
    width: 100%;
  }

  .header-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    height: 3.72rem;
  }

  @include media-breakpoint-down(lg) {
    .logo {
      height: 3rem;
    }
  }

  @include media-breakpoint-down(md) {
    height: 5rem;

    .logo {
      height: 3rem;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 4rem;

    .logo {
      height: 2.5rem;
    }
  }
}

.header-nav {
  border-radius: 1000px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: fit-content;

  .header-nav-list {
    display: block;
  }

  > svg {
    cursor: pointer;
    display: none;
    height: unset;
    padding: 0.5rem;
    width: 2.5rem;

    path {
      stroke: #310A31;
    }
  }
}

.header-nav, .sidebar {
  .nav-list {
    display: flex;
    gap: 2rem;

    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      position: relative;

      &:has(.nav-dropdown) {
        svg {
          transform: rotate(180deg);
          transition: transform 0.25s linear;
        }
      }
    }

    a, a:hover, span {
      align-items: center;
      color: #310A31;
      cursor: pointer;
      display: flex;
      font-size: 1.125rem;
      font-weight: 800;

      svg {
        height: unset;
        margin-left: 0.625rem;
        transform: rotate(0deg);
        transition: transform 0.25s linear;
        width: 1rem;

        path {
          stroke: #310A31;
        }
      }
    }
  }

  .nav-dropdown {
    background: #4B1A4B;
    border-radius: 2rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 5rem;
    padding: 2.5rem 2rem;
    position: absolute;
    right: 0;
    width: 15.5rem;

    img {
      position: absolute;
      right: 3.5rem;
      top: -0.75rem;
      width: 1.75rem;
    }

    > div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    a, a:hover, span {
      color: #F6E27F;
      font-family: Montserrat;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    span {
      cursor: initial;
    }
  }
}

.header-nav .nav-dropdown {
  &.specialisms, &.sectors {
    justify-content: flex-start;
    right: -8rem;
    width: 45rem;

    > img {
      right: 12rem;
    }

    .nav-dropdown-list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      height: 20rem;

      h6 {
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      a {
        font-size: 1rem;
        width: 12rem;
      }

      .nav-dropdown-list-group {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      }
    }
  }

  &.sectors {
    width: 59rem;

    > img {
      right: 10.5rem;
    }

    .nav-dropdown-list {
      height: 30rem
    }
  }
}

@include media-breakpoint-down(lg) {
  .header-nav .nav-list {
    gap: 1.75rem;

    span svg {
      margin-left: 8px;
    }
  }
}

@include media-breakpoint-down(md) {
  .header .header-inner {
    .header-nav-list {
      display: none;
    }

    .header-nav > svg {
      display: block;
    }
  }
}

.sidebar {
  background: rgba(0, 0, 0, 0.50);
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 222;

  @include media-breakpoint-down(md) {
    &.active {
      display: flex;
    }
  }

  .sidebar-container {
    background: #FFF;
    height: 100%;
    max-width: 23rem;
    overflow: auto;
    padding: 2rem 1.5rem;
    width: 90%;
  }

  .sidebar-backdrop {
    flex-grow: 1;
  }

  .sidebar-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .logo {
      height: 2.5rem;
    }

    > svg {
      cursor: pointer;
      height: unset;
      padding: 0.5rem;
      transform: rotate(90deg);
      width: 2.125rem;

      path {
        stroke: #310A31;
      }
    }
  }

  .nav-list {
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    margin-top: 5rem;

    a, a:hover, span {
      font-size: 1.5rem;
    }

    > div {
      align-items: flex-start;
    }
  }

  .nav-dropdown {
    align-items: flex-start;
    background: none;
    gap: 2rem;
    justify-content: flex-start;
    padding: 0;
    position: relative;
    margin-top: 2rem;
    width: 100%;

    img {
      display: none;
    }

    a, a:hover, span {
      color: #310A31;
      font-size: 1rem;
      text-overflow: ellipsis;
    }

    .nav-dropdown-list-group {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    h6 {
      display: none;
      margin-bottom: 0;
    }
  }
}

.unitas-site {
  .top-nav {
    background: #3A84C9;

    a {
      color: #FFFFFF;
      font-weight: 600;
    }

    .top-nav-quicklinks {
      span {
        color: #FFF;
      }

      svg path {
        stroke: #FFF;
      }
    }

    .dropdown {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #3A84C9;

      .dropdown-container .top-nav-left a,
      .dropdown-container .top-nav-right a {
        font-weight: 600;
      }
    }
  }

  .header-outer-container {
    border-bottom: 1px solid rgba(26, 26, 26, 0.15);
  }

  .header {
    .logo {
      height: 3rem;
    }

    @include media-breakpoint-down(md) {
      .logo {
        height: 2.5rem;
      }
    }

    @include media-breakpoint-down(sm) {
      .logo {
        height: 2rem;
      }
    }

    .header-nav {
      .nav-list {
        a, span {
          color: #3A84C9;
          font-weight: 600;
        }

        svg path {
          stroke: #3A84C9;
        }
      }

      > svg path {
        stroke: #3A84C9;
      }

      .nav-dropdown {
        background: #5AA1E4;

        &.sectors, &.specialisms {
          width: 30rem;

          .nav-dropdown-list {
            height: 24rem;

            h6 {
              color: #1A1A1A;
              font-weight: 400;
            }
          }
        }

        &.sectors {
          width: 43.5rem;
        }

        a, a:hover, span {
          color: #FFF;
          font-family: Poppins;
        }
      }
    }
  }

  .sidebar {
    .sidebar-header > svg path {
      stroke: #3A84C9;
    }

    .nav-list {
      a, span {
        color: #3A84C9;
        font-family: Poppins;
        font-weight: 600;

        svg path {
          stroke: #FFF;
        }
      }
    }
  }
}

.prime-site {
  .header-outer-container {
    background: #163BF2;
    top: 0;
  }

  .header {
    .logo {
      height: 3rem;
    }

    @include media-breakpoint-down(md) {
      .logo {
        height: 3rem;
      }
    }

    @include media-breakpoint-down(sm) {
      .logo {
        height: 2.5rem;
      }
    }

    .header-nav {
      .nav-list {
        a, span {
          color: #FFF;
          font-family: Plus Jakarta Sans;
          font-weight: 800;
        }

        svg path {
          stroke: #FFF;
        }
      }

      > svg path {
        stroke: #FFF;
      }

      .nav-dropdown {
        background: #EB52F9;
        border-radius: 0;
        justify-content: flex-start;
        padding: 2.5rem 2rem;
        right: unset;
        width: 16.5rem;

        img {
          right: 44%;
        }

        a, a:hover, span {
          font-weight: 700;
        }
      }
    }
  }

  .sidebar {
    .sidebar-container {
      border-right: 2px solid #D3CDD0;
      background: #163BF2;
    }

    .sidebar-header > svg path {
      stroke: #FFF;
    }

    .nav-list {
      a, span {
        color: #FFF;
        font-family: Plus Jakarta Sans;
        font-weight: 700;

        svg path {
          stroke: #FFF;
        }
      }
    }
  }
}
