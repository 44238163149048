#content > .resource-page {
    background: #FFF;

    .loader svg {
        margin-bottom: 4rem;
    }

    .title {
        margin-bottom: 3rem;

        h2 {
            color: #1A1A1A;
        }
    }

    .resource-components {
        align-items: flex-start;
        display: flex;
        gap: 4rem;
        padding-bottom: 4.5rem;
    }

    .resource-content {
        flex-grow: 1;

        > a {
            align-items: center;
            color: #52050A;
            display: flex;
            font-size: 1.25rem;
            font-weight: 800;
            margin-bottom: 2rem;

            svg {
                height: unset;
                margin-right: 1rem;
                transform: rotate(180deg);
                width: 1.25rem;

                path {
                    stroke: #FF958C;
                }
            }
        }

        .resource-details {
            align-items: center;
            display: flex;
            gap: 1.5rem;
            justify-content: space-between;

            .resource-details-inner {
                align-items: center;
                background: #EFF0F2;
                border-radius: 62.5rem;
                display: flex;
                flex: 1;
                gap: 3.5rem;
                height: 3.5rem;
                padding: 0 2rem;

                span {
                    color: #1A1A1A;
                    font-size: 1.125rem;
                }

                span:first-child {
                    color: #7E8192;
                }
            }
        }

        .featured-img {
            margin: 4rem 0 3rem;
            text-align: center;

            .img-container {
                background: #D9D9D9;
                border-radius: 1rem;
                display: flex;
                height: 27.375rem;
                width: 100%;

                img {
                    border-radius: 1rem;
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }

            span {
                color: #7E8192;
                font-size: 0.875rem;
                line-height: 1.5rem; /* 171.429% */
            }
        }

        .text {
            .wp-block-heading {
                color: #7E8192;
                font-family: Montserrat;
                font-size: 1.5rem;
                font-weight: 800;
                line-height: 2.25rem; /* 150% */
                margin: 1rem 0;
            }

            .wp-block-separator {
                display: none;
            }

            p {
                color: #1A1A1A;
                font-family: Montserrat;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.75rem;

                strong {
                    color: #7E8192;
                    font-size: 1.5rem;
                    font-weight: 800;
                    line-height: 2.25rem; /* 150% */
                }

                &:has(strong) {
                    margin: 2rem 0 1rem;
                }
            }

            a {
                color: #F6E27F;
                font-weight: 800;
            }

            img {
                margin-left: 0.5rem;
            }
        }

        .resource-tags {
            align-items: flex-start;
            display: none;
            gap: 0.5rem;
            margin-top: 3rem;

            &.active {
                display: flex;
            }

            .resource-tag {
                align-items: center;
                background: rgba(255, 149, 140, 0.10);
                border-radius: 0.5rem;
                display: flex;
                gap: 0.375rem;
                justify-content: center;
                padding: 0.5rem 1rem;
                width: fit-content;

                span {
                    color: #E77268;
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
        }
    }

    .resource-info {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .resource-author {
            align-items: flex-start;
            background: #FFF;
            border: 1px solid rgba(49, 10, 49, 0.15);
            border-radius: 1.5rem;
            box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
            display: flex;
            padding: 1.5rem 1.5rem 2rem 1.5rem;
            gap: 1.5rem;

            > img {
                background: #D9D9D9;
                border-radius: 62.5rem;
                height: 6.25rem;
                width: 6.25rem;
            }

            h6 {
                color: #1A1A1A;
                font-weight: 800;
                margin-bottom: 0.25rem;
            }

            span {
                color: #7E8192;
                font-size: 1rem;
            }

            .author-contact {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-top: 1.5rem;

                div, a {
                    align-items: center;
                    display: flex;
                }

                span {
                    color: #52050A;
                    font-size: 1rem;
                    font-weight: 800;
                    margin-left: 0.75rem;
                }

                svg {
                    height: unset;
                    width: 1.25rem;

                    path {
                        stroke: #FF958C;
                    }
                }

                div:last-child svg path {
                    fill: #FF958C;
                    stroke: none;
                }
            }
        }

        .resource-share {
            align-items: flex-start;
            background: #52050A;
            border-radius: 2rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 1.75rem 1.75rem 2rem 1.75rem;

            h5 {
                margin-bottom: 0.75rem;
            }

            span {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
            }

            .action-container {
                position: relative;
                width: 100%;

                form {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-bottom: 1rem;
                }

                input {
                    font-size: 1rem;
                    height: 3.5rem;
                    padding: 1.06rem;
                }

                .btn {
                    min-height: 3.875rem;
                    width: 100%;

                    .loader svg {
                        margin: 0;
                    }
                }

                div {
                    text-align: center;
                }

                span, a {
                    color: rgba(255, 255, 255, 0.70);
                    font-size: 0.875rem;

                    a {
                        text-decoration: underline;
                    }
                }

                .error-msg, .success-msg {
                    left: 0;
                    top: -1.5rem;
                }
            }

            .socials {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;

                svg {
                    height: unset;
                    width: 1.5rem;

                    path {
                        fill: #F6E27F;
                        stroke: none;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .resource-components {
            flex-direction: column;
            gap: 3rem;
            padding-bottom: 2.5rem;
        }

        .resource-info, .resource-info > div {
            width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        .title {
            margin-bottom: 2rem;
        }

        .resource-content {
            .featured-img {
                margin: 3rem 0;

                .img-container {
                    height: 11.5rem;
                }
            }

            .resource-details {
                flex-direction: column;
                gap: 1rem;

                .resource-details-inner {
                    flex: unset;
                    gap: 2rem;
                    width: 100%;
                }
            }
        }

        .resource-author {
            flex-direction: column;

            > img {
                margin: 0 auto;
            }

            .author-contact span {
                margin-left: 0.75rem;
            }
        }
    }
}

.unitas-site #content > .resource-page {
    .title h2 {
        font-weight: 900;
    }

    .resource-content {
        > a {
            color: #C57B36;
            font-weight: 600;

            svg path {
                stroke: #C57B36;
            }
        }

        .text {
            .wp-block-heading {
                color: #7E8192;
                font-family: Poppins;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 2.25rem; /* 150% */
                margin: 2rem 0 1.5rem;
            }

            p {
                color: #1A1A1A;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.75rem;

                strong {
                    color: #7E8192;
                    font-family: Poppins;
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 2.25rem; /* 150% */
                    margin: 2rem 0 1.5rem;
                }

                &:has(strong) {
                    margin: 2rem 0 1.5rem;
                }

                &:first-child {
                    color: #7E8192;
                    font-size: 1.25rem;
                    line-height: 2rem; /* 160% */
                    margin-bottom: 3rem;
                }
            }
        }
    }

    .resource-info {
        .resource-author {
            h6 {
                font-size: 1.5rem;
                font-weight: 400;
            }

            .author-contact {
                span {
                    color: #1A1A1A;
                    font-weight: 600;
                }

                svg path {
                    stroke: #C57B36;
                }

                div:last-child svg path {
                    fill: #C57B36;
                    stroke: none;
                }
            }
        }

        .resource-share {
            border-radius: 1.125rem;
            background: #00400E;

            .action-container {
                input {
                    height: 3.8125rem;
                    padding: 1.0625rem 1.5rem;
                }

                .btn {
                    padding: 0.8125rem 2rem;
                    min-height: 3.5rem;
                }
            }

            .socials svg path {
                fill: #C57B36;
                stroke: none;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .title h2 {
            font-size: 3rem;
        }
    }
}

.prime-site #content > .resource-page {
    background: #000;

    .title {
        margin-bottom: 2rem;

        h2 {
            color: #FFF;
            font-family: ArdelaEdgeX02-Black;
        }
    }

    .resource-content {
        > a {
            color: #EB52F9;
            font-weight: 700;

            svg path {
                stroke: #EB52F9;
            }
        }

        .resource-details {
            flex-direction: row-reverse;

            .resource-details-inner {
                background: none;
                border-radius: 0;
                gap: 1rem;
                height: unset;
                padding: 0;

                &:first-child {
                    justify-content: flex-end;
                }

                span {
                    color: #D3CDD0;
                    font-size: 1rem;
                }

                span:first-child {
                    color: rgba(211, 205, 208, 0.70);
                }
            }
        }

        .featured-img .img-container {
            background: #7E8192;
            border-radius: 0;

            img {
                border-radius: 0;
            }
        }

        .text {
            .wp-block-heading {
                color: #D3CDD0;
                font-family: Plus Jakarta Sans;
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 2.25rem; /* 150% */
                margin: 2rem 0 1.5rem;
            }

            p {
                color: rgba(211, 205, 208, 0.70);
                font-family: Plus Jakarta Sans;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.75rem;

                strong {
                    color: #D3CDD0;
                    font-family: Plus Jakarta Sans;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 2.25rem; /* 150% */
                    margin: 2rem 0 1.5rem;
                }

                &:has(strong) {
                    margin: 2rem 0 1.5rem;
                }

                &:first-child {
                    color: #D3CDD0;
                    font-size: 1.25rem;
                    line-height: 2rem; /* 160% */
                }
            }
        }

        .resource-tags .resource-tag {
            border-radius: 62.5rem;
            background: rgba(235, 82, 249, 0.30);
            padding: 0.4375rem 1rem 0.5625rem 1rem;

            span {
                color: #F689FF;
                font-weight: 700;
            }
        }
    }

    .resource-info {
        .resource-author {
            border: 1px solid #747474;
            border-radius: 0;
            background: #101010;

            > img {
                border-radius: 0;
            }

            h6 {
                color: #D3CDD0;
                font-family: ArdelaEdgeX02-Black;
                font-size: 1.5rem;
                font-weight: 400;
            }

            span {
                color: rgba(211, 205, 208, 0.70);
            }

            .author-contact {
                span {
                    color: #FFF;
                    font-weight: 700;
                }

                svg path {
                    stroke: #FFF;
                }

                div:last-child svg path {
                    fill: #FFF;
                    stroke: none;
                }
            }
        }

        .resource-share {
            background: #EB52F9;
            border-radius: 0;
            padding: 2rem;

            h5 {
                color: #000;
                font-family: ArdelaEdgeX02-Black;
            }

            span {
                color: #000;
            }

            .action-container {
                input {
                    background: rgba(0, 0, 0, 0.30);
                    border-radius: 0;
                    color: #FFF;
                    font-weight: 500;
                    padding: 1rem 1.5rem 1.125rem 1.5rem;

                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: rgba(255, 255, 255, 0.70);
                    }

                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: rgba(255, 255, 255, 0.70);
                    }

                    &::-ms-input-placeholder { /* Microsoft Edge */
                        color: rgba(255, 255, 255, 0.70);
                    }
                }

                .btn {
                    background: #FFF;
                    color: #000;
                    padding: 0.75rem 2rem 0.875rem 2rem;
                }

                a {
                    color: #000;
                }
            }

            .socials svg path {
                fill: #FFF;
                stroke: none;
            }
        }
    }
}
