.the-prime-group {
    background: linear-gradient(180deg, #000 0%, #1A0223 100%);

    .pattern {
        background: url('../static/huddle/prime-pattern-2.png') bottom left no-repeat, url('../static/huddle/prime-pattern-1.png') top right no-repeat;
        background-size: 25%, 50%;
        mix-blend-mode: lighten;
    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 2rem 0 6rem;

        > img {
            margin-bottom: 6rem;
            width: 17.5rem;
        }
    }

    .title {
        text-align: center;

        h1 {
            color: #D3CDD0;
            font-family: ArdelaEdgeX02-Regular;
            font-size: 6rem;
            font-weight: 400;
            line-height: 110%; /* 6.6rem */
        }

        a, a:hover {
            background: url('../static/prime/tpg-pattern.jpg') left;
            background-size: 50%;
            font-family: ArdelaEdgeX02-ExtraBold;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-font-smoothing: antialiased;
        }
    }

    .text {
        margin: 3rem 0 8rem;
        max-width: 52.25rem;
        text-align: center;

        p {
            color: #D3CDD0;
            font-family: Plus Jakarta Sans;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.875rem; /* 150% */
        }
    }

    .sub-text {
        text-align: center;

        p {
            color: #EB52F9;
            font-family: Plus Jakarta Sans;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.875rem; /* 187.5% */
            letter-spacing: 0.32rem;
            text-transform: uppercase;
        }
    }

    .logos {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        max-width: 58.25rem;
        width: 100%;

        img {
            height: 4rem;
        }
    }

    @include media-breakpoint-down(md) {
        .pattern {
            background: url('../static/huddle/prime-pattern-mob-2.png') bottom left no-repeat, url('../static/huddle/prime-pattern-mob-1.png') top right no-repeat;
            background-size: 70%;
            mix-blend-mode: lighten;
        }

        .content {
            padding: 2rem 0;

            > img {
                margin-bottom: 4rem;
                width: 15rem;
            }
        }

        .title h1 {
            font-size: 2.875rem;
        }

        .text {
            margin: 2rem 0 6rem;
        }

        .logos {
            flex-direction: column;
            gap: 2rem;

            img {
                height: unset;
                width: 10rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .pattern {
            background-size: 80%;
        }

        .title h1 {
            font-size: 2.5rem;
        }
    }
}

.prime-site .the-prime-group {
    background: #000;

    .pattern {
        background: url('../static/prime/top-bg.png') top right no-repeat, url('../static/prime/middle-bg.png') bottom left no-repeat;
        background-size: 25%, 18%;
        margin-bottom: -25rem;
        mix-blend-mode: lighten;
        padding-bottom: 25rem;
    }

    .content {
        padding: 2rem 0;
    }

    .text {
        margin: 3rem 0 6rem;
    }

    .sub-text p {
        color: #EB52F9;
    }

    @include media-breakpoint-down(md) {
        .pattern {
            background: url('../static/prime/top-bg-mob.png') top -4rem right no-repeat, url('../static/prime/middle-bg.png') bottom left no-repeat;
            background-size: 30%, 20%;
            margin-bottom: -7rem;
            padding-bottom: 7rem;
        }

        .title h1 {
            font-size: 4rem;
        }

        .text {
            margin: 3rem 0 4rem;
        }

        .logos {
            gap: 3rem;

            img {
                height: 3rem;
                width: unset;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .pattern {
            background-size: 40%, 20%;
        }

        .title h1 {
            font-size: 3rem;
        }
    }
}
