.home .hero {
    background: url('../static/huddle/hero-image.png') center no-repeat #FF958C;
    background-size: cover;

    .content {
        align-self: stretch;
        margin-top: 6rem;
        max-width: 38.5rem;
    }

    .text-container {
        display: flex;
        flex-direction: column;
    }

    .title {
        h1 {
            color: #310A31;
        }
    }

    .text {
        margin-top: 2.5rem;

        p {
            color: #310A31;
            font-size: 1.25rem;
        }
    }

    .btn-container {
        margin-top: 4rem;
    }

    @include media-breakpoint-down(md) {
        background: url('../static/huddle/hero-image-mob.png') top center no-repeat #FF958C;
        background-size: 100%;

        .content {
            margin-top: 2rem;
        }

        h1 {
            font-size: 2.75rem;
        }

        .text-container {
            max-width: unset;
        }
    }
}

.unitas-site .home .hero {
    background: #F9F9F9;
    overflow: hidden;
    position: relative;

    .content {
        margin-top: 0;

        .text-container {
            position: relative;

            > img {
                position: absolute;
                left: 114.5%;
                top: -14rem;
                width: 62.5rem;
                z-index: -5;
            }
        }
    }

    .title {
        h1, a {
            color: #1A1A1A;
            font-family: Fraunces;
            font-weight: 900;
            line-height: 6rem;
            position: relative;
            z-index: 1;
        }

        a {
            background: linear-gradient(20deg, #CB7F39 0%, #C59436 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .text p {
        color: #7E8192;
    }

    @include media-breakpoint-down(lg) {
        > img {
            height: 120%;
            top: -7em;
            right: -33rem;
        }
    }

    @include media-breakpoint-down(md) {
        background: url('../static/unitas/home-hero-mob.png') top right no-repeat #F9F9F9;
        background-size: 18.6875rem;

        .content {
            margin-top: 4rem;

            .text-container > img {
                display: none;
            }
        }

        .title {
            h1, a {
                font-size: 6rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .title {
            h1, a {
                font-size: 4rem;
                line-height: normal;
            }
        }
    }
}
