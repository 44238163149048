.home,
.work-for-us,
.executive-search,
.embedded-talent,
.contract-interim,
.permanent-hires,
.corporate,
.diversity,
.company {
    > .work-for-us {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 10.41%, rgba(0, 0, 0, 0.44) 34.26%, rgba(0, 0, 0, 0.36) 55.33%, rgba(0, 0, 0, 0.00) 100%), url('../static/huddle/work-for-us.jpg') center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;

        .content {
            align-self: flex-start;
            max-width: 38.5rem;
        }

        .title a {
            color: #F6E27F;
            background: #310A31;
        }

        .text {
            margin-top: 2rem;

            p {
                color: #FFF;
                font-size: 1.125rem;
                font-weight: 600;
            }
        }

        .grid-container {
            margin-top: 3rem;

            .link-container {
                align-items: center;
                display: flex;
                height: 3rem;
            }

            a {
                align-items: center;
                display: flex;
                justify-content: space-between;
                max-width: 14.75rem;
                width: 100%;

                span {
                    color: #F6E27F;
                    font-size: 1.25rem;
                    font-weight: 800;
                    max-width: 12.375rem;
                }
            }

            svg {
                height: unset;
                margin-left: 1rem;
                width: 1.25rem;

                path {
                    stroke: #FF958C;
                }
            }
        }

        .cv-container {
            background: #310A31;
            border-radius: 2rem;
            margin-top: 4rem;
            padding: 2rem;

            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
                margin: 1rem 0 2rem;
            }

            .link-container {
                display: flex;
                justify-content: flex-end;

                a {
                    align-items: center;
                    color: #F6E27F;
                    display: flex;
                    font-size: 1.25rem;
                    font-weight: 800;
                }

                svg {
                    height: unset;
                    margin-left: 1rem;
                    width: 1.25rem;

                    path {
                        stroke: #FF958C;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('../static/huddle/work-for-us.jpg') center right -34rem no-repeat, lightgray -1217.123px 0px / 610.604% 100% no-repeat;
            background-size: cover;

            .content {
                max-width: unset;
            }
        }

        @include media-breakpoint-down(sm) {
            .grid-container {
                margin-top: 2rem;

                .link-container {
                    height: unset;

                    a {
                        justify-content: space-between;
                        max-width: 100%;
                    }
                }
            }

            .cv-container {
                margin-top: 3rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .work-for-us,
    .embedded-talent,
    .contract-interim,
    .permanent-hires,
    .corporate,
    .diversity {
        > .work-for-us {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('../static/huddle/work-for-us.jpg') center right -24rem no-repeat, lightgray -1217.123px 0px / 610.604% 100% no-repeat;
            background-size: cover;
        }
    }
}

.executive-search {
    > .work-for-us {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 10.41%, rgba(0, 0, 0, 0.75) 34.26%, rgba(0, 0, 0, 0.61) 55.33%, rgba(0, 0, 0, 0.00) 100%), url('../static/huddle/executive-work-for-us.jpeg') center no-repeat, lightgray 0px -70.059px / 100% 117.087% no-repeat;
        background-size: cover;

        .title {
            h2, a {
                font-family: Playfair Display;
                font-weight: 400;
            }

            a {
                color: #FFF;
                background: 0;
            }
        }

        .text p {
            font-family: Plus Jakarta Sans;
            font-size: 1.125rem;
            font-weight: 400;
        }

        .grid-container {
            a span {
                color: #E0B45C;
                font-family: Plus Jakarta Sans;
            }

            svg path {
                stroke: #E0B45C;
            }
        }

        @include media-breakpoint-down(md) {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), url('../static/huddle/executive-work-for-us.jpeg') center right -12rem no-repeat, lightgray -655.917px 0px / 362.356% 100% no-repeat;
            background-size: cover;
        }
    }
}

.unitas-site {
    .home,
    .work-for-us,
    .executive-search,
    .embedded-talent,
    .contract-interim,
    .permanent-hires,
    .corporate,
    .diversity {
        > .work-for-us {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 10.41%, rgba(0, 0, 0, 0.75) 34.26%, rgba(0, 0, 0, 0.61) 55.33%, rgba(0, 0, 0, 0.00) 100%), url('../static/unitas/work-for-us.jpeg') center no-repeat, lightgray 1688px -5.42px / 118.889% 100.943% no-repeat;
            background-size: cover;

            .title a {
                background: none;
                color: #FFF;
                font-family: Fraunces;
            }

            .text p {
                font-weight: 400;
            }

            .grid-container {
                a span {
                    color: #F49944;
                    font-weight: 600;
                }

                svg path {
                    stroke: #C57B36;
                }
            }

            .cv-container {
                background: #1A1A1A;
                border-radius: 1.5rem;

                a {
                    color: #F49944;
                    font-weight: 600;
                }

                .link-container svg path {
                    stroke: #C57B36;
                }
            }

            @include media-breakpoint-down(md) {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url('../static/unitas/work-for-us.jpeg') center right -26rem no-repeat, lightgray 951.508px 0px / 503.952% 100% no-repeat;
                background-size: cover;
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 3rem;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .work-for-us,
        .embedded-talent,
        .contract-interim,
        .permanent-hires,
        .corporate,
        .diversity {
            > .work-for-us {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url('../static/unitas/work-for-us.jpeg') center right -18rem no-repeat, lightgray 951.508px 0px / 503.952% 100% no-repeat;
                background-size: cover;
            }
        }
    }

    .executive-search > .work-for-us {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 10.41%, rgba(0, 0, 0, 0.75) 34.26%, rgba(0, 0, 0, 0.61) 55.33%, rgba(0, 0, 0, 0.00) 100%), url('../static/unitas/executive-work-for-us.jpeg') center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;

        .grid-container {
            a span {
                color: #FFC700;
            }

            svg path {
                stroke: #FFC700;
            }
        }

        @include media-breakpoint-down(md) {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), url('../static/unitas/executive-work-for-us.jpeg') center no-repeat, lightgray -585.402px 0px / 358.4% 100% no-repeat;
            background-size: cover;
        }
    }
}

.prime-site {
    .home, .company, .work-for-us {
        > .work-for-us {
            background: linear-gradient(80deg, rgba(0, 0, 0, 0.95) 25.67%, rgba(0, 0, 0, 0.84) 38.84%, rgba(0, 0, 0, 0.68) 50.47%, rgba(0, 0, 0, 0.00) 75.13%), url('../static/prime/work-for-us.jpeg') top center no-repeat, lightgray 50% / cover no-repeat;
            background-size: cover;

            .title h2 {
                font-size: 5rem;
            }

            .text p {
                color: #D3CDD0;
                font-weight: 500;
            }

            .grid-container {
                a span {
                    color: #EB52F9;
                    font-weight: 700;
                }

                svg path {
                    stroke: #EB52F9;
                }
            }

            .cv-container {
                background: #EB52F9;
                border-radius: 0;

                h5 {
                    color: #000;
                    font-family: ArdelaEdgeX02-Black;
                }

                p {
                    color: rgba(0, 0, 0, 0.70);
                    font-family: Plus Jakarta Sans;
                }

                .link-container {
                    a {
                        color: #FFF;
                        font-weight: 700;
                    }

                    svg path {
                        stroke: #FFF;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                background: linear-gradient(67deg, rgba(0, 0, 0, 0.95) 30.68%, rgba(0, 0, 0, 0.84) 50.68%, rgba(0, 0, 0, 0.68) 68.34%, rgba(0, 0, 0, 0.00) 105.79%), url('../static/prime/work-for-us.jpeg') top center no-repeat, lightgray 50% / cover no-repeat;

                .title h2 {
                    font-size: 3rem;
                }

                .grid-container > div {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {
                .title h2 {
                    font-size: 2rem;
                }

                .grid-container {
                    margin-top: 3rem;
                }

                .cv-container h5 {
                    font-size: 1.75rem;
                }
            }
        }
    }

    .home > .work-for-us {
        background: #000;

        .pattern {
            background: url('../static/prime/bottom-bg.png') bottom right no-repeat;
            background-size: 45%;
            mix-blend-mode: lighten;
        }

        .content {
            align-items: center;
            align-self: center;
            padding: 2rem 0;
            max-width: 51.625rem;
        }

        .title h2 {
            font-size: 3.5rem;
            text-align: center;
        }

        .text p {
            text-align: center;
        }

        .grid-container {
            margin-top: 2.75rem;

            > div {
                display: flex;
                justify-content: center;
            }
        }

        .cv-container {
            margin-top: 5.25rem;
            max-width: 43.625rem;
        }

        @include media-breakpoint-down(md) {
            .pattern {
                background: none;
            }

            .grid-container > div {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        @include media-breakpoint-down(sm) {
            .title h2 {
                font-size: 2.5rem;
            }

            .grid-container {
                margin-top: 3rem;
            }

            .cv-container h5 {
                font-size: 1.75rem;
            }
        }
    }
}
