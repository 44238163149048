.leadership-team,
.our-story,
.our-process,
.work-for-us,
.contact-us,
.executive-search,
.corporate,
.diversity,
.company {
    .leadership-hero {
        background: url('../static/huddle/leadership.png') top -5rem right -5rem no-repeat #FFF;
        background-size: clamp(35rem, 48%, 50rem) auto;

        .content {
            margin-top: 2rem;
        }

        .text-container {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            width: 45.0625rem;

            > h5 {
                color: #00D7A9;
                font-weight: 600;
            }
        }

        .title {
            margin-top: 2rem;

            h1 {
                color: #02C39A;
                font-size: 5rem;
            }

            a {
                color: #F6E27F;
                background: #310A31;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            margin-top: 3rem;
            max-width: 37.0625rem;

            p {
                color: #7E8192;
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 2rem;
            }
        }

        .info-container {
            margin-top: 6rem;

            .info-box {
                align-items: flex-start;
                background-color: #02C39A;
                border-radius: 3rem;
                display: flex;
                flex-direction: column;
                padding: 3rem 4rem;

                &:has(.info-calculation) {
                    padding: 3rem 4rem 4rem;
                }

                .info-title {
                    margin-bottom: 2rem;

                    h5 {
                        color: #310A31;
                    }
                }
            }

            .info-details {
                align-items: flex-start;
                align-self: stretch;
                display: flex;
                justify-content: space-between;
            }

            .info-description p {
                font-size: 1.5rem;
                line-height: 2.375rem;
            }

            .info-signatures {
                align-items: center;
                display: flex;
                gap: 4rem;
                justify-content: center;
                margin: 1.5rem auto 0;

                .info-signature {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;

                    img {
                        height: 5.5rem;
                        margin-bottom: 1rem;
                    }

                    h6 {
                        color: #310A31;
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 2px;
                    }

                    span {
                        color: rgba(49, 10, 49, 0.30);
                        font-size: 0.875rem;
                    }
                }
            }

            .info-calculation {
                align-items: center;
                display: flex;
                justify-content: space-between;
                text-align: center;

                > div {
                    margin: 1rem 1.5rem;
                }

                h5 {
                    font-size: 1.5rem;
                    margin-bottom: 0.75rem;
                }

                p {
                    color: rgba(255, 255, 255, 0.70);
                    font-size: 1rem;
                }

                svg {
                    background: #310A31;
                    border-radius: 62.5rem;
                    height: 4rem;
                    overflow: visible;
                    padding: 1rem;
                    width: 4rem;
                }
            }
        }

        @include media-breakpoint-down(md) {
            background: url('../static/huddle/leadership.png') top -1.5rem right -1.5rem no-repeat #FFF;
            background-size: clamp(14rem, 48%, 21rem) auto;

            .title {
                max-width: 35rem;
            }

            .content {
                margin-top: 4rem;
            }

            .text-container {
                width: unset;

                > h5 {
                    font-size: 1.75rem;
                }
            }

            .title {
                h1 {
                    font-size: 4rem;
                }
            }

            .text {
                margin-top: 2rem;
                max-width: unset;
            }

            .info-container {
                margin-top: 3rem;

                .info-box {
                    padding: 2rem 1.5rem;

                    &:has(.info-calculation) {
                        padding: 2rem 1.5rem;
                    }

                    .info-title h5 {
                        font-size: 1.75rem;
                    }
                }

                .info-details {
                    flex-wrap: wrap;
                    gap: 2rem;
                    justify-content: space-around;
                    text-align: center;

                    h2 {
                        font-size: 4rem;
                    }

                    span {
                        font-size: 1rem;
                    }
                }

                .info-description p {
                    line-height: 2.125rem;
                }

                .info-signatures {
                    margin-top: 2rem;
                }

                .info-calculation {
                    flex-direction: column;
                    margin: 0 auto;

                    > div {
                        margin: 1.5rem;
                        max-width: 30rem;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .text-container > h5 {
                font-size: 1.25rem;
            }

            .title h1 {
                font-size: 3rem;
            }

            .info-container {
                margin-top: 3rem;

                .info-box {
                    padding: 2rem 1.5rem;
                }

                .info-details {
                    flex-direction: column;
                    gap: 1.5rem;
                    align-items: center;
                }

                .info-description p {
                    font-size: 1.25rem;
                }

                .info-signatures {
                    flex-direction: column;
                    gap: 1.5rem;

                    .info-signature {
                        align-items: center;
                    }
                }
            }
        }
    }
}

.our-story .leadership-hero {
    background-image: url('../static/huddle/our-story.png');
}

.our-process .leadership-hero {
    background-image: url('../static/huddle/our-process.png');
}

.work-for-us .leadership-hero {
    background-image: url('../static/huddle/work-for-us.png');
}

.contact-us .leadership-hero {
    background-image: url('../static/huddle/contact-us.png');
}

.corporate .leadership-hero {
    background-image: url('../static/huddle/corporate.png');
}

.diversity .leadership-hero {
    background-image: url('../static/huddle/diversity.png');
}

.work-for-us, .contact-us {
    .leadership-hero {
        .text-container > h5 {
            color: #310A31;
        }

        .roles-box {
            margin-top: 8rem;
        }

        .visit-grid-container {
            margin-top: 7.25rem;
        }

        .title {
            margin-top: 2rem;

            h1 {
                color: #310A31;
                font-size: 5rem;
            }

            a {
                color: #F6E27F;
                background: #310A31;
            }
        }

        @include media-breakpoint-down(md) {
            .title {
                max-width: 35rem;
            }

            .roles-box {
                margin-top: 3rem;
            }

            .visit-grid-container {
                margin-top: 2.25rem;
            }

            .title h1 {
                font-size: 4rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .title h1 {
                font-size: 3rem;
            }
        }
    }
}

.corporate, .diversity {
    .leadership-hero {
        .text-container {
            max-width: 66%;

            h5 {
                color: #8762CB;
            }
        }

        .title h1 {
            color: #8762CB;
        }

        .info-container {
            margin-top: 8rem;

            h3 {
                color: #8762CB;
            }
        }

        .info-grid-container {
            margin-top: 2.25rem;
        }

        .principles-container {
            border-radius: 3rem;
            background: #8762CB;
            height: 100%;
            padding: 3rem;

            .principles-title {
                margin: 1.5rem 0 1rem;
            }

            h2 {
                color: #FF958C;
            }

            h2, h5 {
                font-weight: 800;
            }

            p {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1rem;
                font-weight: 600;
            }
        }

        @include media-breakpoint-down(md) {
            .text-container {
                max-width: 100%;

                h5 {
                    max-width: 66%;
                }
            }

            .info-container {
                margin-top: 3rem;
            }

            .info-grid-container {
                margin-top: 1.25rem;

                > div {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .info-container h3 {
                font-size: 2.375rem;
            }

            .principles-container {
                border-radius: 3rem;
                padding: 1.5rem 1.5rem 2rem 1.5rem;
            }
        }
    }
}

.diversity .leadership-hero .principles-container h5 {
    max-width: 60%;
}

.executive-search {
    .leadership-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 10.41%, rgba(0, 0, 0, 0.70) 34.26%, rgba(0, 0, 0, 0.57) 65.41%, rgba(0, 0, 0, 0.00) 100%), url('../static/huddle/executive-leadership.jpg') center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;

        .content {
            margin-top: 0;
        }

        .title {
            margin-top: 0;

            h1 {
                color: #FFF;
                font-family: Playfair Display;
                font-size: 4rem;
                font-weight: 400;
            }
        }

        .text {
            align-self: flex-start;
            max-width: unset;

            p {
                color: #FFF;
                font-family: Plus Jakarta Sans;
                font-size: 1.125rem;
                font-weight: 400;
            }
        }

        .info-container {
            margin: 7rem 0 2rem;

            .info-box:has(.info-calculation) {
                background-color: unset;
                padding: 0;

                .info-title {
                    display: none;
                }
            }

            .info-calculation {
                > div {
                    margin: 0 2rem;
                }

                h5 {
                    font-family: Playfair Display;
                    font-size: 1.75rem;
                }

                p {
                    font-family: Plus Jakarta Sans;
                    font-weight: 400;
                }

                svg {
                    background: #7E8192;
                }
            }
        }

        @include media-breakpoint-down(md) {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 23.61%, rgba(0, 0, 0, 0.79) 43.10%, rgba(0, 0, 0, 0.65) 65.41%, rgba(0, 0, 0, 0.00) 100%), url('../static/huddle/executive-leadership.jpg') center right -16rem no-repeat, lightgray -1389.25px 0px / 518.8% 100% no-repeat;
            background-size: cover;

            .info-container {
                margin: 3rem 0 0;

                .info-calculation {
                    flex-direction: column;
                    margin: 0 auto;

                    h5 {
                        font-size: 2rem;
                        margin-bottom: 1rem;
                    }

                    > div {
                        margin: 2rem;
                        max-width: 30rem;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .title {
                h1 {
                    font-size: 3rem;
                }
            }
        }
    }
}

.unitas-site {
    .leadership-team,
    .our-story,
    .our-process,
    .work-for-us,
    .contact-us,
    .executive-search,
    .corporate,
    .diversity {
        .leadership-hero {
            background: #F9F9F9;
            overflow: hidden;

            .text-container {
                position: relative;

                > img {
                    position: absolute;
                    left: 105%;
                    top: -18rem;
                    width: 62.5rem;
                    z-index: -5;
                }

                h5 {
                    color: #496049;
                    font-family: Poppins;
                    font-size: 1.75rem;
                    font-weight: 400;
                }
            }

            .title {
                h1, a {
                    background: none;
                    color: #496049;
                    font-family: Fraunces;
                    font-size: 5rem;
                    font-weight: 900;
                }
            }

            .text p {
                font-weight: 400;
            }

            .info-container {
                .info-box {
                    background: #496049;
                    border-radius: 1.5rem;

                    .info-title h5 {
                        color: #FFF;
                        font-size: 2rem;
                        font-weight: 900;
                    }

                    .info-detail {
                        h2 {
                            font-size: 4rem;
                        }

                        span {
                            font-size: 1rem;
                        }
                    }
                }

                .info-signatures .info-signature {
                    h6 {
                        color: #FFF;
                        font-family: Poppins;
                        font-weight: 400;
                    }

                    span {
                        color: rgba(255, 255, 255, 0.30);
                    }
                }

                .info-calculation {
                    svg {
                        background: #B1C9B1;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .text-container > img {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                .text-container h5 {
                    font-size: 1.25rem;
                }

                .title {
                    h1, a {
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    .work-for-us, .contact-us {
        .leadership-hero {
            .text-container h5,
            .title h1,
            .title a {
                color: #56422F;
            }
        }
    }

    .corporate,
    .diversity {
        .leadership-hero {
            .text-container h5 {
                color: #824554;
            }

            .title h1, .info-container h3 {
                color: #824554;
            }

            .principles-container {
                background: #824554;

                h2 {
                    color: #1A1A1A;
                    font-size: 4rem;
                    font-weight: 400;
                }

                h5 {
                    color: #FFF;
                    font-weight: 400;
                }

                p {
                    font-weight: 400;
                }

                @include media-breakpoint-down(sm) {
                    h2 {
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .leadership-team .leadership-hero {
            background: url('../static/unitas/leadership-mob.png') top right no-repeat #F9F9F9;
            background-size: 18.6875rem;
        }

        .our-story .leadership-hero {
            background: url('../static/unitas/our-story-hero-mob.png') top right no-repeat #F9F9F9;
            background-size: 18.6875rem;
        }

        .our-process .leadership-hero {
            background: url('../static/unitas/our-process-hero-mob.png') top right no-repeat #F9F9F9;
            background-size: 18.6875rem;
        }

        .work-for-us .leadership-hero {
            background: url('../static/unitas/work-for-us-mob.png') top right no-repeat #F9F9F9;
            background-size: 18.6875rem;
        }

        .contact-us .leadership-hero {
            background: url('../static/unitas/contact-us-mob.png') top right no-repeat #F9F9F9;
            background-size: 17.6875rem;
        }

        .corporate .leadership-hero {
            background: url('../static/unitas/corporate-mob.png') top right no-repeat #F9F9F9;
            background-size: 18.6875rem;
        }

        .diversity .leadership-hero {
            background: url('../static/unitas/diversity-mob.png') top right no-repeat #F9F9F9;
            background-size: 18.6875rem;
        }
    }

    .executive-search .leadership-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 23.61%, rgba(0, 0, 0, 0.79) 43.10%, rgba(0, 0, 0, 0.65) 65.41%, rgba(0, 0, 0, 0.00) 100%), url('../static/unitas/executive-leadership.jpeg') center no-repeat, lightgray 50% / cover no-repeat;
        background-size: cover;

        .title h1 {
            color: #FFF;
            font-weight: 400;
        }

        .text p {
            font-family: Poppins;
        }

        .info-container {
            .info-box {
                background-color: unset;
                padding: 0;
            }

            .info-calculation svg {
                background: #710058;
            }
        }

        @include media-breakpoint-down(md) {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 22.88%, rgba(0, 0, 0, 0.79) 41.8%, rgba(0, 0, 0, 0.65) 63.46%, rgba(0, 0, 0, 0.00) 97.03%), url('../static/unitas/executive-leadership.jpeg') center right -24rem no-repeat, lightgray -1172px 0px / 522.4% 100% no-repeat;
            background-size: cover;
        }
    }
}

.prime-site {
    .leadership-team,
    .our-story,
    .our-process,
    .work-for-us,
    .contact-us {
        .leadership-hero {
            background: #000;
            overflow: hidden;

            .text-container {
                position: relative;

                > img {
                    position: absolute;
                    left: 105%;
                    top: -18rem;
                    width: 60rem;
                    z-index: -5;
                }

                h5 {
                    color: #00FFA3;
                    font-family: Plus Jakarta Sans;
                    font-weight: 500;
                }
            }

            .title h1 {
                color: #00FFA3;
                font-family: ArdelaEdgeX02-Black;
            }

            .text p {
                color: #D3CDD0;
                font-style: normal;
                font-weight: 500;
            }

            .info-container {
                .info-box {
                    background: #00FFA3;
                    border-radius: 0;

                    .info-title h5 {
                        color: #000;
                        font-family: ArdelaEdgeX02-Black;
                    }

                    .info-description p {
                        color: #000;
                    }
                }

                .info-calculation {
                    h5, p {
                        color: #000;
                    }

                    svg {
                        background: #000;
                        border-radius: 0;
                    }
                }

                .info-details {
                    .info-detail {
                        h2 {
                            color: #000;
                            font-family: ArdelaEdgeX02-Black;
                            font-size: 5rem;
                            line-height: 5rem; /* 100% */
                            margin-bottom: 1.5rem;
                        }

                        span {
                            color: #000;
                        }
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .text-container {
                    position: unset;

                    > img {
                        left: unset;
                        right: -4%;
                        top: -1rem;
                        width: 44%;
                    }
                }
            }
        }
    }

    .work-for-us, .contact-us {
        .leadership-hero {
            .text-container h5,
            .title h1,
            .title a {
                color: #FFB800;
            }
        }
    }
}
