.search-bar {
    display: flex;
    width: 100%;

    img.search-icon {
        height: 1.25rem;
        left: 2rem;
        pointer-events: none;
        position: absolute;
        width: 1.25rem;
    }

    .textfield {
        .input-container {
            input {
                flex-grow: 1;
                padding: 1.5rem 7.5rem 1.5rem 4.25rem;
            }

            @include media-breakpoint-down(md) {
                input {
                    padding: 1.12rem 7rem 1.12rem 3.75rem;
                }

                img.search-icon {
                    left: 1.5rem;
                }
            }
        }
    }

    &.jobs {
        border: 1px solid rgba(49, 10, 49, 0.15);
        box-shadow: 0 1px 2px  rgba(16, 24, 40, 0.05);
    }
}

.prime-site .search-bar {
    .textfield .input-container input {
        background: #252525;
        border-radius: 0;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        color: #FFF;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #D3CDD0;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #D3CDD0;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #D3CDD0;
        }
    }
}
