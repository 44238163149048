.looking-to-hire .contract-hires {
    background: #8762CB;
    overflow: hidden;

    .content {
        align-self: flex-end;
        position: relative;
        max-width: 38.5rem;

        > img {
            height: 225%;
            position: absolute;
            right: 114.5%;
            top: -62%;
        }
    }

    .title {
        a {
            color: #F6E27F;
            background: #310A31;
        }
    }

    .text {
        margin-top: 2rem;
    }

    .btn-container {
        margin-top: 4rem;
    }

    @include media-breakpoint-down(md) {
        background: url('../static/huddle/contract-hires-mob.png') top right no-repeat #8762CB;
        background-size: 17rem;

        .content {
            margin-top: 4rem;
            max-width: unset;
        }

        .btn-container {
            margin-top: 3rem;
        }
    }
}

.unitas-site .looking-to-hire .contract-hires {
    background:  url('../static/unitas/contract-hires.jpg') right no-repeat;
    background-size: cover;

    .title a {
        background: none;
        color: #FFF;
        font-family: Fraunces;
    }

    @include media-breakpoint-down(md) {
        background:  url('../static/unitas/contract-hires-mob.jpg') top right no-repeat #3A84C9;
        background-size: 85%;

        .content {
            margin: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        .title h2 {
            font-size: 3rem;
        }
    }
}
